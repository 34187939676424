import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  NetworkSite,
  SdmOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { sortBy } from "@inrange/calculations/utils";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Dropdown from "../input/Dropdown";
import InputContainer from "../input/InputContainer";
import { ColumnNoDefaults, Row } from "../layout/Flex";
import {
  Confirmation,
  Description,
  MarketplaceButton,
} from "./marketplace-styles";
import { siteCanBuy, siteCanSell } from "./marketplace-utils";
import MarketplaceBuyOfferDetails from "./MarketplaceBuyOfferDetails";
import MarketplaceLoadingOfferDetails from "./MarketplaceLoadingOfferDetails";
import MarketplaceNoOfferDetails from "./MarketplaceNoOfferDetails";
import MarketplaceSellOfferDetails from "./MarketplaceSellOfferDetails";

interface MarketplaceOfferProps {
  org: Organisation;
  siteId?: string;
  userEmail: string;
  sites: OrgSiteListEntry[];
  site?: Site;
  offerSite?: OrgSiteListEntry | NetworkSite;
  setSelectedSiteIdFn: (siteId?: string) => void;
  addSiteUrl: string;
  notifyAddSiteFn: () => void;
  type: string;
  isLoading: boolean;
  acceptOfferFn: () => void;
  sdmOffer?: SdmOffer;
}

const MarketplaceOffer: React.FC<MarketplaceOfferProps> = ({
  org,
  siteId,
  userEmail,
  sites,
  site,
  offerSite,
  setSelectedSiteIdFn,
  addSiteUrl,
  notifyAddSiteFn,
  type,
  isLoading,
  acceptOfferFn,
  sdmOffer,
}) => {
  return (
    <>
      <OfferPanel>
        <OfferMaxWidth>
          <Header>Explore Marketplace</Header>
          <Row
            $padding={"0"}
            $margin={"0 0 10px 0"}
            $alignItems={"center"}
            $maxWidth={"616px"}
            $justifyContent={"stretch"}
          >
            <InputContainer style={{ flex: 1 }}>
              <Dropdown
                value={siteId === undefined ? "" : siteId}
                onChange={(e) => {
                  setSelectedSiteIdFn(e.target.value || undefined);
                }}
                data-testid="marketplace-site-dropdown"
              >
                <option value={""}>Select a site to explore with</option>
                {sites
                  .filter((site) => {
                    return siteCanBuy(org, site!) || siteCanSell(org, site!);
                  })
                  .sort(sortBy((site) => site.name))
                  .map((site, index) => (
                    <option key={index} value={site.id}>
                      {site.name}
                    </option>
                  ))}
              </Dropdown>
            </InputContainer>
            {siteId !== undefined && (
              <AddSiteLinkText>
                or{" "}
                <Link to={addSiteUrl} onClick={notifyAddSiteFn}>
                  Add a site
                </Link>
              </AddSiteLinkText>
            )}
          </Row>
          {siteId === undefined && (
            <Confirmation style={{ marginBottom: "20px" }}>
              Select a site or interact with sites on the map to see what the
              InRange Marketplace has to offer.
            </Confirmation>
          )}
          <ColumnNoDefaults $gap={"8px"}>
            {siteId === undefined && (
              <>
                <Description
                  data-testid={`marketplace-offer-description-loading-false`}
                >
                  If you have another site not in the list above you want to buy
                  energy for, add it! {"You'll"} get more value from the InRange
                  Marketplace with every additional site.
                </Description>
                <Row
                  $padding={"0"}
                  $margin={"10px 0 10px 0"}
                  $justifyContent={"start"}
                  $alignItems={"center"}
                >
                  <MarketplaceButton
                    fontWeight="400"
                    fontSize="14px"
                    label={"Add a site"}
                    to={addSiteUrl}
                    onClick={notifyAddSiteFn}
                  />
                </Row>
              </>
            )}
            {isLoading && siteId && site && (
              <MarketplaceLoadingOfferDetails site={site} type={type} />
            )}
            {!isLoading && siteId && site && offerSite && sdmOffer && (
              <>
                {type === "buy" ? (
                  <MarketplaceBuyOfferDetails
                    org={org}
                    site={site as Site}
                    offerSite={offerSite}
                    sdmOffer={sdmOffer}
                    acceptOfferFn={acceptOfferFn}
                    userEmail={userEmail}
                    setSelectedSiteIdFn={setSelectedSiteIdFn}
                  />
                ) : (
                  <MarketplaceSellOfferDetails
                    site={site as Site}
                    offerSite={offerSite}
                    sdmOffer={sdmOffer}
                    acceptOfferFn={acceptOfferFn}
                    userEmail={userEmail}
                    setSelectedSiteIdFn={setSelectedSiteIdFn}
                  />
                )}
              </>
            )}
            {!isLoading && siteId && site && !sdmOffer && (
              <MarketplaceNoOfferDetails
                site={site}
                type={type}
                acceptOfferFn={acceptOfferFn}
                setSelectedSiteIdFn={setSelectedSiteIdFn}
              />
            )}
          </ColumnNoDefaults>
        </OfferMaxWidth>
      </OfferPanel>
    </>
  );
};

const OfferPanel = styled.div`
  padding: 20px;
  background: #fff;
  line-height: 1.5;
`;

const OfferMaxWidth = styled.div`
  max-width: 700px;
`;

const Header = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`;

const AddSiteLinkText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey700};

  a {
    text-decoration: none;
    color: #5486ad;
  }
`;

export default MarketplaceOffer;
