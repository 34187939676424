import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import "leaflet/dist/leaflet.css";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SimpleButton } from "../../exports";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import { RowNoDefaults } from "../layout/Flex";
import { siteCanSell } from "../marketplace/marketplace-utils";
import "./map.css";
import {
  ForceWhiteText,
  MARKETPLACE_COLOR_BLUE,
  MARKETPLACE_COLOR_PURPLE,
  PopupSubHeading,
  PopupText,
  PopupTextHighlighted,
  PopupTextLine,
} from "./marketplace-map-styles";
import RelatedOwnershipLine from "./RelatedOwnershipLine";

const MySiteSellMarker: React.FC<{
  org: Organisation;
  site: OrgSiteListEntry | Site;
  isExplore: boolean;
}> = ({ org, site, isExplore }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlSpillSiteId = params.get("spillSiteId") || undefined;

  const sdmMatches = "sdmMatches" in site ? site.sdmMatches : [];
  const numNetworkExportSites =
    sdmMatches.filter((match) => match.sellerId === site.id).length +
    (site.energyFlowAnnual.exported > 0 ? 1 : 0);

  const totalExportableEnergy =
    site.energyFlowAnnual.networkExport +
    site.energyFlowAnnual.exported +
    (site.exportLimit === 0 ? site.energyFlowAnnual.curtailed : 0);

  return (
    <div>
      <PopupText
        $fontWeight="0"
        $clickable={true}
        onClick={() => {
          navigate(`/org/${org.id}/site/${site.id}`);
        }}
      >
        {site.name}
      </PopupText>
      <PopupSubHeading
        $color={isExplore ? MARKETPLACE_COLOR_PURPLE : MARKETPLACE_COLOR_BLUE}
      >
        Total exportable energy
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatUnitsToNearestTen(totalExportableEnergy, "kWh")}
        </PopupTextHighlighted>{" "}
        / year
      </PopupTextLine>
      <PopupTextLine>
        Selling to{" "}
        <PopupTextHighlighted>{numNetworkExportSites}</PopupTextHighlighted>{" "}
        network site{numNetworkExportSites === 1 ? "" : "s"}
      </PopupTextLine>
      <RelatedOwnershipLine org={org} site={site} />
      {isExplore && siteCanSell(org, site) && (
        <ForceWhiteText>
          <RowNoDefaults $justifyContent="center" $margin="15px 0">
            <SimpleButton
              fontWeight="400"
              label={
                site.exportLimit === 0 ? "Get wired offer" : "Get an offer"
              }
              width={"60%"}
              to={`/org/${org.id}/marketplace?siteId=${site.id}&offerType=sell${urlSpillSiteId ? `&spillSiteId=${urlSpillSiteId}` : ""}`}
              background={"#a2a6ff"}
            />
          </RowNoDefaults>
        </ForceWhiteText>
      )}
    </div>
  );
};

export default MySiteSellMarker;
