import React from "react";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatCurrencyToNearestTen } from "../../formatting/formatCurrency";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import Grid from "../layout/Grid";
import {
  LandlordSiteMetricValues,
  MetricCardsRow,
  SiteMetricCard,
} from "./index";
import { getProcurementNote } from "./utils";

interface OwnerOccupierSiteMetricCardsProps {
  metrics: LandlordSiteMetricValues;
  currencyCode: string;
}

export const OwnerOccupierSiteMetricCards: React.FC<
  OwnerOccupierSiteMetricCardsProps
> = ({ metrics, currencyCode }) => {
  const hhDemandDataString = metrics.hhDataUploaded
    ? ""
    : " Submitted half hourly demand data improves accuracy.";
  const procurementNote = getProcurementNote(
    metrics.hasOnsiteBehindMeter,
    metrics.numNetworkImportSites
  );
  return (
    <MetricCardsRow>
      <Grid
        $cols={1}
        $colwidth={"1fr"}
        $gap={"8px"}
        $margin={"0"}
        $width={"100%"}
        $minWidth={"170px"}
      >
        <SiteMetricCard
          title="Revenue year 1"
          value={formatCurrencyToNearestTen(
            metrics.revenueYearOne,
            currencyCode,
            0,
            false
          )}
          tooltipText="Revenue from turnover rent per month, which is a percentage of operating profit derived from the on-site and export PPAs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
        <SiteMetricCard
          title="Lifetime revenue"
          value={formatCurrencyToNearestTen(
            metrics.lifetimeRevenue,
            currencyCode,
            0,
            false
          )}
          tooltipText="Revenue from turnover rent per month, which is a percentage of operating profit derived from the on-site and export PPAs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
      </Grid>
      <Grid
        $cols={1}
        $colwidth={"1fr"}
        $gap={"8px"}
        $margin={"0"}
        $width={"100%"}
        $minWidth={"192px"}
      >
        <SiteMetricCard
          title="Up front investment"
          value={formatCurrencyToNearestTen(
            metrics.initialInvestment,
            currencyCode,
            0,
            false
          )}
          tooltipText="Up front investment is the cost to procure and install the on-site system, as well as accounts for enabling works costs."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
        <SiteMetricCard
          title="Total project cost"
          value={formatCurrencyToNearestTen(
            metrics.totalProjectCost,
            currencyCode,
            0,
            false
          )}
          tooltipText="Total project cost accounts for up front investment as well as lifetime costs like operations, maintenance, and insurance."
          tooltipPosition="left"
          color="white"
          height="80px"
          width="100%"
          titleColor="#5486AD"
        />
      </Grid>
      <SiteMetricCard
        title="IRR"
        value={formatPercentage(metrics.irr)}
        tooltipText="IRR is calculated based on expected monthly revenue as it relates to total project cost."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "license"}
      />
      <SiteMetricCard
        title="Payback"
        value={metrics.payback ? metrics.payback + " months" : "N/A"}
        tooltipText="Payback is the number of months required to pay back the up front investment given the expected annual revenue."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "license"}
      />
      <SiteMetricCard
        title="Generation potential year 1"
        value={formatUnitsToNearestTen(
          metrics.generationPotentialYearOne,
          "kWh"
        )}
        tooltipText="Estimated potential for energy generated by the on-site system in the first year."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "lease"}
      />
      <SiteMetricCard
        title="Demand fulfilled by InRange vs. others"
        value={formatPercentage(metrics.demandFulfilledByInRange)}
        note={procurementNote}
        tooltipText={`Projected percentage of overall that will be fulfilled by on-site generation and/or the InRange network instead of by other suppliers.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
        visible={metrics.investmentModel === "lease"}
      />
      <SiteMetricCard
        title="Savings in year 1"
        value={formatCurrencyToNearestTen(
          metrics.savingsYearOne!,
          currencyCode,
          0,
          false
        )}
        note={`vs. ${(metrics.marketTariff * 100).toFixed(
          2
        )} ${fractionalCurrencySymbol(currencyCode)}/kWh market tariff`}
        tooltipText="The potential savings from the energy procured in year 1, considering the difference between the current market tariff and the InRange tariff."
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
      <SiteMetricCard
        title="Procurement cost from InRange in year 1"
        value={formatCurrencyToNearestTen(
          metrics.procurementCostYearOne,
          currencyCode,
          0,
          false
        )}
        tooltipText={`Procured behind the meter demand that can be met by on-site generation and/or the InRange network in year 1, multiplied by the relevant fixed tariff.${hhDemandDataString}`}
        tooltipPosition="left"
        color="white"
        height="168px"
        width="100%"
        titleColor="#5486AD"
      />
    </MetricCardsRow>
  );
};
