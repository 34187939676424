import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { OrgSiteListEntry } from "@inrange/building-manager-api-client/models-organisation";

interface OwnershipFilteringObject {
  [key: string]: {
    name: string;
    siteIDs: string[];
  };
}

interface TrackUserInteractionData {
  filter_action: string;
  org_name: string;
  filtered_org_names?: string[];
  filtered_org_name?: string;
  filtered_park_names?: string[];
  filtered_park_name?: string;
}

const getSiteType = (site: OrgSiteListEntry, orgId: string): string => {
  const orgOwnership = site.siteOwnerships.find(
    (ownership) => ownership.orgID === orgId
  );
  return site.operationalStatus === "operational"
    ? "operational"
    : orgOwnership?.ownership || "unknown";
};

export const getSitesByType = (
  sites: OrgSiteListEntry[],
  orgID: string,
  allowedTypes: string[]
): Record<string, OrgSiteListEntry[]> => {
  return sites.reduce(
    (acc, site) => {
      const siteType = getSiteType(site, orgID);
      if (acc[siteType] !== undefined) {
        acc[siteType].push(site);
      }
      return acc;
    },
    allowedTypes.reduce(
      (acc, type) => ({ ...acc, [type]: [] }),
      {} as Record<string, OrgSiteListEntry[]>
    )
  );
};

export const generateOwnershipFilteringObject = (
  sites: OrgSiteListEntry[],
  type: string
): OwnershipFilteringObject => {
  return sites.reduce((acc, site) => {
    const ownerships = site.siteOwnerships;
    const ownershipsWithType = ownerships.filter(
      (ownership) => ownership.ownership === type
    );

    if (ownershipsWithType.length === 0) {
      if (!acc["undefined"]) {
        acc["undefined"] = { name: "Undefined", siteIDs: [] };
      }
      acc["undefined"].siteIDs.push(site.id);
    }

    ownershipsWithType.forEach((ownership) => {
      if (!acc[ownership.orgID]) {
        acc[ownership.orgID] = { name: ownership.name!, siteIDs: [] };
      }
      acc[ownership.orgID].siteIDs.push(ownership.siteID!);
    });
    return acc;
  }, {} as OwnershipFilteringObject);
};

export const generateOwnershipFilteringSlackNotification = (
  filterAction: string,
  orgName: string,
  filteringOrgNames: string[],
  userEmail: string
): void => {
  const data: TrackUserInteractionData = {
    filter_action: filterAction,
    org_name: orgName,
  };
  if (filteringOrgNames.length > 1) {
    data.filtered_park_names = filteringOrgNames;
  } else {
    data.filtered_org_name = filteringOrgNames[0];
  }
  trackUserInteraction(
    data,
    "BASIC_ACTION",
    "SITE_LIST_FILTER",
    userEmail,
    "customer-app"
  );
};

export const generateParkFilteringSlackNotification = (
  filterAction: string,
  orgName: string,
  filteringParkNames: string[],
  userEmail: string
): void => {
  const data: TrackUserInteractionData = {
    filter_action: filterAction,
    org_name: orgName,
  };
  if (filteringParkNames.length > 1) {
    data.filtered_park_names = filteringParkNames;
  } else {
    data.filtered_park_name = filteringParkNames[0];
  }
  trackUserInteraction(
    data,
    "BASIC_ACTION",
    "SITE_LIST_FILTER_PARK",
    userEmail,
    "customer-app"
  );
};
