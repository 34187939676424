import { OrgSiteListEntry } from "@inrange/building-manager-api-client/models-organisation";
import {
  AggregateMatchedEnergy,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { SelectFilterOption } from "@inrange/theme-components";

export const getLatestActualsMonthIndex = (
  site: Site | OrgSiteListEntry,
  testMonthToday?: number
): number | undefined => {
  // January is 0, February is 1 etc., we add 1 as the keys in our site attributes are 1-12 for each month
  const monthToday = testMonthToday || new Date().getMonth() + 1;
  let lastMonth: number | undefined = monthToday === 1 ? 12 : monthToday - 1;
  if (!site.energyFlowMonthlyActuals![lastMonth]?.metadata) {
    lastMonth = lastMonth === 1 ? 12 : lastMonth - 1;
    if (!site.energyFlowMonthlyActuals![lastMonth]?.metadata) {
      // No actuals for the previous month, or the one before
      lastMonth = undefined;
    }
  }
  return lastMonth;
};

export const getBehindMeterCost = (
  matchedEnergy: AggregateMatchedEnergy[]
): number => {
  let behindMeterCost = 0;
  for (const matchedEnergyItem of matchedEnergy) {
    if (
      matchedEnergyItem.sinkType === "demand" &&
      matchedEnergyItem.sourceType !== "gridImport"
    ) {
      behindMeterCost +=
        parseFloat(Number(matchedEnergyItem.energyFlow).toFixed(2)) *
        matchedEnergyItem.energyMatchConfig.tariff;
    }
  }
  return behindMeterCost;
};

export const sortOwnershipSelectOptions = (
  options: SelectFilterOption[]
): SelectFilterOption[] => {
  return options.sort((a, b) => {
    if (a.label === "Undefined") return 1;
    if (b.label === "Undefined") return -1;
    return a.label.localeCompare(b.label);
  });
};
