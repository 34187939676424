import { Site } from "@inrange/building-manager-api-client/models-site";
import { SummaryBoxMetrics } from "@inrange/theme-components";
import { formatPercentage } from "@inrange/theme-components/formatting";
import React from "react";
import { getLatestActualsMonthIndex } from "../../views/sitelist-utils";

const OperationalPerformanceRatio: React.FC<{ site: Site }> = ({ site }) => {
  const params = new URL(document.location.toString()).searchParams;
  const showPR = params.get("showPR");

  if (!showPR || site.installedCapacity === 0) {
    return null;
  }

  const testMonthToday = Number(params.get("testMonthToday"));
  const lastMonthIndex = getLatestActualsMonthIndex(site, testMonthToday);
  const calculatePerformanceRatio = (monthIndex: number) => {
    const energyFlowActuals = site.energyFlowMonthlyActuals![monthIndex];
    const energyFlowForecast = site.energyFlowMonthly![monthIndex];

    let lastMonthPr = 0;
    if (!energyFlowActuals.metadata) {
      // No actuals for the previous month
      return undefined;
    } else if (energyFlowForecast.generation > 0) {
      // Non-zero forecast, return PR
      lastMonthPr =
        energyFlowActuals.energyFlow.generation / energyFlowForecast.generation;
    } else if (energyFlowActuals.energyFlow.generation > 0) {
      // Zero forecast, and non-zero actuals, return 1
      lastMonthPr = 1;
    }
    // Zero forecast and actuals, return 0
    return lastMonthPr;
  };

  if (!lastMonthIndex) {
    return null;
  }

  const lastMonthPR = calculatePerformanceRatio(lastMonthIndex);
  const lastLastMonthPR = calculatePerformanceRatio(
    lastMonthIndex - 1 > 1 ? lastMonthIndex - 1 : 12
  );
  let totalForecastGeneration = 0;
  let totalActualGeneration = 0;
  let monthsActual = 0;
  for (let i = 1; i <= 12; i++) {
    const energyFlowActuals = site.energyFlowMonthlyActuals![i];
    if (
      energyFlowActuals &&
      energyFlowActuals.metadata &&
      energyFlowActuals.energyFlow.generation
    ) {
      totalForecastGeneration += site.energyFlowMonthly![i].generation;
      totalActualGeneration += energyFlowActuals.energyFlow.generation;
      monthsActual += 1;
    }
  }
  let annualPr = 0;
  if (totalForecastGeneration > 0) {
    // Non-zero forecast, return PR
    annualPr = totalActualGeneration / totalForecastGeneration;
  } else if (totalActualGeneration > 0) {
    // Zero forecast, and non-zero actuals, return 1
    annualPr = 1;
  }

  const prChange =
    parseFloat((lastMonthPR || 0).toFixed(4)) -
    parseFloat((lastLastMonthPR || 0).toFixed(4));

  const prMetrics = [
    {
      value: `${formatPercentage(lastMonthPR!, 2)}`,
      valueSubText: "Last month",
    },
    ...(lastLastMonthPR !== undefined
      ? [
          {
            value:
              prChange < 0
                ? `↓ ${formatPercentage(Math.abs(prChange), 2)}`
                : prChange > 0
                  ? `↑ ${formatPercentage(Math.abs(prChange), 2)}`
                  : "No change",
            valueSubText: "Trend since prev month",
          },
        ]
      : []),
    {
      value: `${formatPercentage(annualPr, 2)}`,
      valueSubText: `Last ${monthsActual} months`,
    },
  ];

  return (
    <SummaryBoxMetrics
      metrics={prMetrics}
      header={"Performance ratio"}
      grid={false}
    />
  );
};

export default OperationalPerformanceRatio;
