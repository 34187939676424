export const loadSessionStoredValue = (
  orgId: string,
  type: string
): object | undefined => {
  const item = sessionStorage.getItem(`stored-value-${type}`);
  if (!item) {
    return undefined;
  }
  try {
    const itemParsed = JSON.parse(item);
    if (itemParsed && itemParsed.orgId === orgId && itemParsed.value) {
      return itemParsed.value;
    }
  } catch (e) {
    // do nothing
  }
  return undefined;
};

export const saveSessionStoredValue = (
  orgId: string,
  type: string,
  value: object
): void => {
  sessionStorage.setItem(
    `stored-value-${type}`,
    JSON.stringify({ orgId, value })
  );
};
