import axiosConfig from "./axiosConfig";

const postSiteMarketplaceGraphs = async (
  authToken,
  siteId,
  extraSdmBuyOffer,
  userOrgId
) => {
  const urlArgs = [];
  if (userOrgId) {
    urlArgs.push(`userOrgId=${userOrgId}`);
  }
  const { data } = await axiosConfig.post(
    `/site/${siteId}/marketplace_graphs${urlArgs.length ? `?${urlArgs.join("&")}` : ""}`,
    extraSdmBuyOffer,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default postSiteMarketplaceGraphs;
