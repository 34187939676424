import {
  useOrganisation,
  useSiteMarketplaceGraphs,
} from "@inrange/building-manager-api-client";
import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  SdmMatchConfig,
  SdmOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { reversed, sortBy } from "@inrange/calculations/utils";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { SimpleButton } from "../../exports";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import displayId from "../../utils/displayId";
import YearDayEnergyChart from "../charts/YearDayEnergyChart";
import { Column, Row } from "../layout/Flex";
import Loading from "../Loading";
import Modal from "../modal/Modal";
import ModalView from "../modal/ModalView";
import { SPILL_BY_COUNTRY } from "./marketplace-utils";
import { formatMatchedEnergyChartData } from "./marketplaceChartFormatting";

const MarketplaceEnergyCurvesModal: React.FC<{
  organisation: Organisation;
  site: Site;
  offerType: string;
  setShowModal: (showModal: boolean) => void;
  extraSdmBuyOffer?: SdmOffer;
  urlSpillSiteId: string | undefined;
}> = ({
  organisation,
  site,
  offerType,
  setShowModal,
  extraSdmBuyOffer,
  urlSpillSiteId,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [energyProcuredChartPeriod, setEnergyProcuredChartPeriod] = useState<
    "year" | "summer" | "winter"
  >("year");
  const { fetchSiteMarketplaceGraphs } = useSiteMarketplaceGraphs({
    siteId: site.id,
    extraSdmBuyOffer: extraSdmBuyOffer?.config,
    userOrgId: organisation.id,
  });

  const { fetchOrganisation } = useOrganisation(
    organisation.id,
    organisation.id
  );
  const sites = fetchOrganisation.data?.sites;

  const sitesById = useMemo(() => {
    return (sites || []).reduce(
      (acc, site) => {
        acc[site.id] = site;
        return acc;
      },
      {} as Record<string, OrgSiteListEntry>
    );
  }, [sites]);

  const siteMatches = extraSdmBuyOffer
    ? [...site.sdmMatches, extraSdmBuyOffer.config]
    : site.sdmMatches;
  const networkImportMatches = siteMatches.filter(
    (match) => match.buyerId === site.id
  );
  const spillSiteId: string | undefined =
    urlSpillSiteId || SPILL_BY_COUNTRY[site.countryCode];
  const networkExportMatches = [
    ...site.sdmMatches.filter((match) => match.sellerId === site.id),
    ...(site.energyFlowAnnual.exported > 0
      ? ([
          {
            buyerId: "spill",
            sellerId: site.id,
            tariff: site.inrangeExportTariff,
            volume: site.energyFlowAnnual.exported,
            ppaContractType: "sleeved",
            ppaLength: site.exportPPALength,
            ppaIndex: site.exportPPAType === "fixed" ? 0 : site.exportPPAIndex,
          },
        ] as SdmMatchConfig[])
      : []),
  ];

  const selectedMatches = (
    offerType === "buy" ? networkImportMatches : networkExportMatches
  ).sort(reversed(sortBy((match) => match.volume)));

  const rawChartData = fetchSiteMarketplaceGraphs.data?.curves
    ? fetchSiteMarketplaceGraphs.data.curves[
        offerType === "buy" ? "buy" : "sell"
      ][
        selectedMatches[selectedIndex][
          offerType === "buy" ? "sellerId" : "buyerId"
        ]
      ]
    : undefined;
  const chartData = rawChartData
    ? formatMatchedEnergyChartData(rawChartData, energyProcuredChartPeriod)
    : [];

  return (
    <Modal>
      <ModalView
        title={
          offerType === "buy"
            ? `Procuring ${formatUnitsToNearestTen(site.energyFlowAnnual.networkImport + (extraSdmBuyOffer?.config?.volume || 0), "kWh")} annually from ${networkImportMatches.length} site${networkImportMatches.length === 1 ? "" : "s"}`
            : `Selling ${formatUnitsToNearestTen(site.energyFlowAnnual.networkExport + site.energyFlowAnnual.exported, "kWh")} exportable energy annually to ${networkExportMatches.length} site${networkExportMatches.length === 1 ? "" : "s"}`
        }
        fontWeight="500"
        fontSize="20px"
        titlePadding="0"
        width="70%"
        height="650px"
      >
        <Row
          $width="100%"
          $margin="16px 0 0 0"
          $padding="0"
          style={{ flex: 1 }}
        >
          {(fetchOrganisation.isLoading ||
            fetchSiteMarketplaceGraphs.isLoading) && (
            <Loading label="Loading chart data..." />
          )}
          {!(
            fetchOrganisation.isLoading || fetchSiteMarketplaceGraphs.isLoading
          ) && (
            <>
              <Column
                $padding="0"
                style={{
                  maxWidth: "30%",
                  overflowWrap: "break-word",
                  overflowY: "auto",
                  maxHeight: "560px",
                }}
              >
                {selectedMatches.map((match, index) => (
                  <OfferRow
                    key={match.buyerId}
                    isSelected={index === selectedIndex}
                    onClick={() => setSelectedIndex(index)}
                  >
                    <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                      {sitesById[
                        offerType === "buy" ? match.sellerId : match.buyerId
                      ]?.name ||
                        `Network site ${displayId(offerType === "buy" ? match.sellerId : match.buyerId).substring(0, 3)}`}
                    </div>
                    <div style={{ fontSize: "10px" }}>
                      {displayId(
                        offerType === "buy"
                          ? match.sellerId
                          : match.buyerId === "spill" && spillSiteId
                            ? spillSiteId
                            : match.buyerId
                      )}
                    </div>
                    <OfferDetails>
                      <div>
                        {formatUnitsToNearestTen(match.volume, "kWh")} @{" "}
                        {(match.tariff * 100).toFixed(2)}
                        {fractionalCurrencySymbol(site.currencyCode)}
                        {match.isWired ? " (Wired)" : ""}
                      </div>
                      <div>
                        PPA:{" "}
                        {match.ppaIndex === 0
                          ? "Fixed"
                          : `Indexed ${formatPercentage(match.ppaIndex)}`}
                        , {match.ppaLength} years
                      </div>
                    </OfferDetails>
                  </OfferRow>
                ))}
              </Column>
              <Column style={{ flex: 1 }} $padding="0" $margin="0">
                <YearDayEnergyChart
                  header={
                    offerType === "buy"
                      ? "Matched vs. Total exported energy"
                      : "Matched energy"
                  }
                  chartData={chartData}
                  barColors={["#2779A7", "#00022F"]}
                  legendKeys={{
                    Match: "Matched",
                    ...(offerType === "buy"
                      ? { Total: "Total export profile" }
                      : {}),
                  }}
                  energyProcuredChartPeriod={energyProcuredChartPeriod}
                  setEnergyProcuredChartPeriod={setEnergyProcuredChartPeriod}
                  $padding={"0"}
                  height={"100%"}
                  chartHeight={offerType == "buy" ? "330px" : "380px"}
                  tooltipFontSize={"12px"}
                  isGeneratingSite={false}
                  isBatterySite={false}
                  chartMargins={{
                    left: 70,
                    bottom: 40,
                    top: offerType == "buy" ? 20 : 40,
                  }}
                  stacked={false}
                />
                <ShapeDescription>
                  {offerType === "buy" && (
                    <>
                      Based on forecasted on-site generation for the network
                      site and demand of this site. Matched is the quantity of
                      half-hourly supply and demand matched energy for the given
                      time interval. Total export profile is the total amount of
                      exported energy from the network site, irrespective of
                      match amount with this site. Daily views reflect high
                      generation (July 2nd) and low generation (Feb 1st) times
                      of year.
                    </>
                  )}
                  {offerType === "sell" && (
                    <>
                      Energy shape is forecasted based on on-site generation and
                      demand. Daily views show the expected shape during high
                      generation (July 2nd) and low generation (Feb 1st) times
                      of year.
                    </>
                  )}
                </ShapeDescription>
                <Row
                  $justifyContent="end"
                  $alignItems="end"
                  $width="100%"
                  $padding="0"
                  $margin="0"
                >
                  <SimpleButton
                    label="Close"
                    width="150px"
                    onClick={() => setShowModal(false)}
                    data-testid="marketplace-close-energy-shape-button"
                  />
                </Row>
              </Column>
            </>
          )}
        </Row>
      </ModalView>
    </Modal>
  );
};

const OfferRow = styled.div<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? "#F5F5F5" : "transparent"};
  padding: 12px;
  line-height: 1.5;
  &:hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
`;

const OfferDetails = styled.div`
  font-size: 14px;
`;

const ShapeDescription = styled.div`
  font-size: 14px;
  margin: 0px 10px 10px 10px;
`;

export default MarketplaceEnergyCurvesModal;
