import React from "react";
import styled from "styled-components";

const WithReturnToExploreLink: React.FC<{
  setSelectedSiteIdFn: (siteId?: string) => void;
  children: React.ReactNode;
}> = ({ setSelectedSiteIdFn, children }) => (
  <ReturnToExploreBlock>
    {children}
    <ReturnToExploreLink onClick={() => setSelectedSiteIdFn(undefined)}>
      Explore all sites
    </ReturnToExploreLink>
  </ReturnToExploreBlock>
);

const ReturnToExploreBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ReturnToExploreLink = styled.div`
  font-size: 14px;
  color: #5486ad;
  cursor: pointer;
`;

export default WithReturnToExploreLink;
