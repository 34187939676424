import axiosConfig from "./axiosConfig";

const getNetworkSite = async (
  authToken,
  orgId,
  networkSiteIdsChunkStr,
  userOrgId
) => {
  const queryArgs = [`siteIds=${networkSiteIdsChunkStr}`];
  if (userOrgId) {
    queryArgs.push(`userOrgId=${userOrgId}`);
  }
  const { data } = await axiosConfig.get(
    `/organisation/${orgId}/network_site?${queryArgs.join("&")}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getNetworkSite;
