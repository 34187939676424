import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  NetworkSite,
  SdmMatchConfig,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import "leaflet/dist/leaflet.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { fractionalCurrencySymbol } from "../../formatting/currency-utils";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import formatPercentage from "../../formatting/formatPercentage";
import displayId from "../../utils/displayId";
import "./map.css";
import {
  MARKETPLACE_COLOR_YELLOW,
  PopupSubHeading,
  PopupText,
  PopupTextHighlighted,
  PopupTextLine,
} from "./marketplace-map-styles";

const ExistingMatchSellMarker: React.FC<{
  org: Organisation;
  seller: Site;
  site: OrgSiteListEntry | NetworkSite;
  match: SdmMatchConfig;
  isSpillMatchUpdated: boolean;
}> = ({ org, seller, site, match, isSpillMatchUpdated }) => {
  const navigate = useNavigate();

  const isNetworkSite = "isNetwork" in site && site.isNetwork;

  const totalExportableEnergy =
    seller.energyFlowAnnual.networkExport +
    seller.energyFlowAnnual.exported +
    (seller.exportLimit === 0 ? seller.energyFlowAnnual.curtailed : 0);

  return (
    <div>
      <PopupText
        $fontWeight="0"
        $clickable={!isNetworkSite}
        onClick={
          isNetworkSite
            ? undefined
            : () => {
                navigate(`/org/${org.id}/site/${site.id}`);
              }
        }
      >
        {isNetworkSite
          ? `InRange network site ${displayId(site.id).substring(0, 3)}`
          : (site as OrgSiteListEntry).name}
      </PopupText>
      {isNetworkSite && (
        <PopupText $fontWeight="0" $fontSize="10px">
          {displayId(site.id)}
        </PopupText>
      )}
      <PopupSubHeading $color={MARKETPLACE_COLOR_YELLOW}>
        {isSpillMatchUpdated ? (
          <>
            Existing buyer of your energy
            <br />
            Updated sale amount to enable this offer
          </>
        ) : (
          "Buying from this site"
        )}
      </PopupSubHeading>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatUnitsToNearestTen(match.volume, "kWh")}
        </PopupTextHighlighted>{" "}
        / year
      </PopupTextLine>
      <PopupTextLine>
        <PopupTextHighlighted>
          {formatPercentage(match.volume / totalExportableEnergy)}
        </PopupTextHighlighted>{" "}
        of your exportable energy
      </PopupTextLine>
      <PopupTextLine>
        <PopupTextHighlighted>
          {(match.tariff * 100).toFixed(2)}
          {fractionalCurrencySymbol(site.currencyCode)}
        </PopupTextHighlighted>{" "}
        / kWh for {match.ppaLength} years
      </PopupTextLine>
      {isNetworkSite && (
        <PopupText
          $fontWeight="0"
          $fontSize="12px"
          style={{ marginTop: "1.33em" }}
        >
          * Location is approximate
        </PopupText>
      )}
    </div>
  );
};

export default ExistingMatchSellMarker;
