import React from "react";
import styled from "styled-components";
import formatCurrency from "../../formatting/formatCurrency";

export const ProjectCosts: React.FC<{
  hardwareCost: number;
  installationCost: number;
  enablingWorksCosts: number;
  additionalCosts?: number;
  currencyCode: string;
}> = ({
  hardwareCost,
  installationCost,
  enablingWorksCosts,
  additionalCosts,
  currencyCode,
}) => {
  return (
    <ProjectCostList>
      <ProjectCost
        label="Hardware"
        value={formatCurrency(hardwareCost, currencyCode)}
      />
      <ProjectCost
        label="Installation"
        value={formatCurrency(installationCost, currencyCode)}
      />
      <ProjectCost
        label="Enabling Works"
        value={formatCurrency(enablingWorksCosts, currencyCode)}
      />
      {additionalCosts !== undefined && (
        <ProjectCost
          label="Additional Costs"
          value={formatCurrency(additionalCosts, currencyCode)}
        />
      )}
    </ProjectCostList>
  );
};

const ProjectCostList = styled.ul`
  margin: 0 8px;
  padding: 0;
  list-style: none;
`;

const ProjectCost: React.FC<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <ProjectCostListItem>
      <ProjectCostListItemLabel>{label}</ProjectCostListItemLabel>
      <ProjectCostListItemValue>{value}</ProjectCostListItemValue>
    </ProjectCostListItem>
  );
};

const ProjectCostListItem = styled.li`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 8px;
`;

const ProjectCostListItemLabel = styled.p``;

const ProjectCostListItemValue = styled.p`
  text-align: right;
`;
