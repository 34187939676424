export const siteScoresData = {
  "b4e2dec0-b64d-4b24-0org-aaaabbbb0000": {
    "b4e2dec0-b64d-4b24-site-aaaabbbb0000": 4,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0001": 4,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0002": 4,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0003": 3,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0004": 3,
    "b4e2dec0-b64d-4b24-site-aaaabbbb0005": 3,
  },
  "659109cb-b1e0-4c23-8def-3c8278ce9cfb": {
    "ed709b7b-f768-4954-a106-ad1f09b6586d": 1,
    "d5b018e7-b7c3-4ab9-ac33-02a0975bf0c1": 3,
    "c7fce0eb-db39-4ba2-bbc5-0ef913a51622": 4,
    "3c5dd4b0-20d8-445f-801e-de2f8dfceec9": 5,
    "ffd7dead-601b-45da-892c-60f554cbda18": 5,
    "1dab3e13-28cf-4d8f-b67b-51682db29b38": 5,
    "9f4f7fdd-3a5a-417f-9797-9716b91c1814": 2,
  },
  "f93f7fb0-a905-4df3-8c0e-e2d6968e9951": {
    "f43e8654-77c6-4e32-b69d-bbc7619c9505": 5,
    "2369c0dc-74dd-406c-a6dd-a6fbd7fc6ce7": 3,
    "509bd6f1-c475-44a6-84fe-b42331d35233": 3,
    "c1e76a0b-0fb1-4524-a143-9953b4ade992": 5,
    "597169c1-cb9e-4ff6-ba3d-2cca25ea1091": 2,
    "dd924246-9b98-4d16-8b06-3d21171cab98": 3,
    "df64fc16-25ee-4cba-9ffe-d2d64123b857": 3,
    "94b91344-199d-44ef-8e1a-248e99379ed2": 3,
    "a9177fac-0e3a-427a-b45b-7c731a8dc838": 1,
    "b474c8c9-2558-43a4-811e-c93139a82ed5": 3,
    "5f4f0df7-d918-48b2-91eb-dd5f5ee3f651": 5,
    "8657504a-ed05-43a5-809e-867561b2b1e8": 3,
    "921e6a95-4e7e-40f1-b011-f092fa71bc3b": 3,
  },
  "d8aa0101-f6f2-441f-b78c-1fa0c157380f": {
    "c4766562-04c1-4d45-82c5-7b485969a096": 5,
    "f74f6362-463d-432e-b15b-b292439a3a39": 5,
    "fb0b85cf-0052-4491-a750-d27e93eb3cf0": 5,
    "3c80796a-3aba-433b-9691-3141db2c4eab": 5,
    "07c3ea42-1989-4611-a632-0f4976c69e5a": 5,
    "9803f36c-42ff-41a7-8f5b-1909aa4d8f16": 5,
    "a695a4cc-0a17-41fc-9e6b-f0c2893aa9b3": 5,
    "8b5a78b4-87e8-4554-ab4b-d26f341e5e5d": 4,
    "98769c42-c4bd-447e-a685-10aa261fabcb": 4,
    "1be129dc-7b6a-47da-be52-4681207fbcd4": 4,
    "3ac29b08-1aad-4f73-91c7-c8f6b71da016": 3,
    "c2ca59b6-91c8-494c-af75-d6e2dd5f4c7a": 3,
    "7a3a235c-b9ce-4e5c-b475-667b91c563e2": 3,
    "032c1689-52c3-41e9-9c82-5430d17ed08d": 3,
    "d069025d-7042-408c-86e1-8373c58e5afa": 3,
    "eda963f4-4506-4ddb-b261-6ab77ee593b9": 3,
    "7cc24cb0-c277-437d-827e-75659e0cc3f0": 3,
    "b8d6e897-8010-471b-b2f1-31ba447228b1": 3,
    "f2a80526-65ab-4f4b-accb-c0f8e1f05d28": 3,
    "0983b412-f972-474f-960a-053bcc4c4b39": 3,
    "7c390449-0f84-4f5e-b63e-5745eda6438e": 3,
    "a990ed4d-a1da-47a1-8193-cf937e98dc44": 3,
    "099aaeaf-2ba9-4112-9c2a-58d99917b604": 2,
    "4f137439-e2f8-4f20-8e30-fdfbcccba651": 2,
    "08d58803-9cec-4a52-b467-83239bf307cc": 2,
  },

  "99356d8d-7f5a-4b0a-a50a-b0a53589764b": {
    "f3e4bffa-1e60-423e-8b43-3f0499d23d0a": 5,
    "8a1f8768-5d2a-40df-a9b2-52f4b7b01456": 4,
    "0c34c9e5-42bb-42ba-aaf2-84833cf704fb": 4,
    "9f8e6458-d443-496e-88a4-19029010e6bc": 2,
    "38e6205c-d48f-4a27-bef7-eeeb9171389b": 4,
    "64fbb68f-3359-4b07-bdf9-25948a84d436": 2,
    "15827fb6-4e45-46c5-9fe3-9810620a12a0": 2,
    "b084b794-c6a5-4eee-a92f-9b287452929b": 2,
  },
  "f55d8d18-2099-4106-979f-ff5b1a94e639": {
    "e284c741-020e-4db4-973c-aab3bf045294": 5,
    "af88ca2c-a735-4373-882f-010a55575e5e": 4,
    "8c48605c-c10c-47f8-b41b-217ce5316961": 4,
    "be38da42-eaaa-468e-818c-675a4067c23b": 4,
    "021db644-c320-4f61-b5ad-7d1d0b644bfe": 3,
    "6d54a969-0f84-4cb8-85e8-1059f83cb8b2": 3,
    "15204fae-2841-42b4-8986-946759e70c96": 3,
    "50515ce4-e9aa-47e2-92c4-e53892b82d73": 3,
    "ccb49287-891c-4380-8124-1a849da619d1": 3,
    "a3d93733-5129-4c7d-bd33-4daccca5034b": 3,
    "cdb2d67b-59e4-47b2-b895-8ded09c2f77e": 3,
    "96f36587-f042-4bb6-893c-aa9130b7715b": 3,
    "de88ea93-9423-4953-8adf-8dc3338d23fc": 3,
    "3c5eb6c0-7485-431b-8495-3fd049110c79": 2,
    "45fcc66f-c59f-4d1c-bedd-87240223a487": 2,
    "6276c494-04ba-482e-9a47-637e65ea57f9": 2,
    "f0088bf2-238e-45c1-b9b1-d8cbd5bddf65": 2,
    "712f0326-4e83-4b63-956c-b4267ba751d2": 1,
    "1398e6cc-0ee9-4851-9886-405732e314dd": 1,
    "d6a038ea-ef87-45a2-81f4-e72369d914bd": 1,
  },
  "98cabb57-c67a-5fe0-8cc7-4b91e321d77b": {
    "24e49007-327d-449a-bd14-d255f1822d1c": 4,
    "f57b9046-5164-42a9-b044-664bd6986c35": 4,
    "3f84038f-aeb7-45e8-835a-6bb464be0994": 4,
    "43f39212-01eb-411b-a742-23b96580ce01": 4,
    "3d0451c4-7e12-4bbf-aa06-979d86dc00ba": 4,
    "d0cac035-6fc2-407d-af4d-b5938bfd1fd7": 4,
    "c017b1da-79e8-4562-89f8-96c706c7ad91": 4,
    "914f6562-b002-4c3a-848c-aebb68654fe4": 4,
    "f44179d2-0fda-4289-8342-c689bc793c5d": 4,
    "33afc3fe-2fc8-4937-a5ee-b5b81e6b486e": 4,
    "508361b2-e122-4d54-9d82-288569e6dc77": 4,
    "c77c2c4d-6e53-4432-a68f-14efd9e35947": 4,
    "4f179e89-f03c-4c58-9514-ddc392e1d7d2": 4,
    "47a55c56-3f45-498d-92d4-079d7e5d4223": 4,
    "9130c5cf-b749-4d37-8c8a-41a82c916742": 4,
    "4fe9331b-8bd0-427a-9f1d-a2abb9a8e953": 4,
    "d97d93c8-f8b6-4699-9c53-1ff641c9be25": 4,
    "29bb000b-f5be-4b7d-969a-2b64061836cc": 4,
    "1da9d13a-b45f-48bc-bfb8-dd09ed9d59c3": 4,
    "67d5e5c2-f89f-4df6-99d5-ab773cfbd381": 3,
    "74fe881d-ac97-4917-85be-704f5c0c58df": 3,
    "41b769d8-9514-479b-86c0-ae7650988859": 3,
    "117ecdf5-512f-402b-98eb-9a8fc2f0aed4": 3,
    "42061c90-8a55-42e8-b7b5-1693aac5588b": 3,
    "81f0f8c2-e69f-4b4e-a182-91bbe065fca3": 3,
    "c7265138-55d2-40e3-864b-c15e552f3ee8": 3,
    "fa6ddad4-86ca-4e57-b3a1-dd51430b754e": 3,
    "4f8bfc6c-07c5-4e4c-85b3-0a0af3dde9ce": 3,
    "59a6b41b-d001-40c4-b054-fef219493dda": 3,
    "1912faf1-76f7-4164-8551-f922b69f9012": 3,
    "f6ec0d87-ea26-4070-970c-92a0b6cac35b": 2,
    "333ae16e-a2a4-420c-87a4-c7da315f2df2": 2,
    "73bfd895-9850-49ba-b45c-07d0561fb9fe": 2,
  },
  "539d7734-7b37-5f90-94e2-4d216a0582f6": {
    "142bd31d-c33d-4918-9d95-645c8db6e467": 5,
    "a05ff380-fb06-496b-9780-43a8643977d6": 5,
    "89a50518-81ad-48c5-b6f0-4df64403046b": 5,
    "01c813ee-1444-49c4-a10d-c80027598e91": 5,
    "a5c96caf-990a-443d-b5b2-20b4846eb805": 5,
    "19ad89c7-3e0b-4247-856f-c92c3392cb19": 5,
    "cfa9a25b-f294-4112-89df-be64bd388d1e": 5,
    "cf0428c5-290b-4f11-b739-8d4a47b67480": 5,
    "b8fc669e-ef7f-42a2-a256-3ea601241721": 4,
    "1d4bccac-1d30-415f-8cb4-55d35be2d67b": 4,
    "27610b7f-d52d-4ee6-bf7c-a98a0214268b": 4,
    "61b67cb1-5bf5-41ff-b22d-513e84c90b95": 4,
    "239aaf31-66a9-48c4-aa45-b7db1d3b104d": 4,
    "1da9d13a-b45f-48bc-bfb8-dd09ed9d59c3": 4,
    "04ca8328-88fc-42e3-b4ec-4740b1792a4c": 4,
    "7e05b406-e62a-467f-8128-b7fc1557f15b": 4,
    "352610c3-c373-412e-9007-bf621031569e": 4,
    "0983b412-f972-474f-960a-053bcc4c4b39": 4,
    "625f8a63-d11b-4259-b3a8-a984c6f9ca25": 4,
    "daf60099-67d2-438e-9c22-0cd6170ce222": 4,
    "296ff7c0-8765-4f97-a62e-51118061f222": 4,
    "f3d1bea5-ddcf-4996-ba05-42dab56a1eda": 3,
    "c7991d92-74f0-4096-8f71-82adeca16ad4": 3,
    "a1a48e5a-5d4a-4d7a-af2b-5e236b117e15": 3,
    "826eaf23-b6e9-49c0-a537-eb25ae110d81": 3,
    "8ea1dd7a-c30c-4267-8c78-a8f15901590c": 3,
    "6c17e04e-9bd3-400b-a6e4-d80b3b4b36cb": 3,
    "10ef731f-5ada-402a-ac12-dbeca57206b2": 3,
    "49cf49f4-77a2-4233-8248-b437f7cb34a3": 3,
    "75f200eb-999e-47aa-9a52-48819549bc55": 3,
    "52d4c820-6f9e-4b2d-b0dd-04d2bda46d8d": 3,
    "1868ed3e-9256-4ea4-9253-f32cc3e08b31": 3,
    "ac52e15b-766b-4def-811a-f9aa9957d3d0": 3,
    "2ba44375-e5f1-46c0-9037-625d30452b34": 3,
    "8d6d5363-8dcb-47fd-a04e-87149e9e3acb": 3,
    "a64a6a3a-3eee-4a43-ab8a-ba2d666d99bf": 2,
    "1b7a9a15-ae16-41cf-8475-2ef3797e3e58": 2,
    "ed7277e9-08ed-4656-9705-a2d0c99196da": 2,
    "111cec80-347c-415e-bb0f-e54ec74c9864": 2,
    "a9247a8c-1497-48d9-bc34-1290356810d0": 2,
    "77cc456e-1387-4a63-9402-da9ea10bdb8b": 2,
    "26e81ef1-65da-4808-b584-4a852377bc51": 2,
    "82a5a8a0-cbc7-4145-9047-40a62210fee4": 2,
    "d33736e0-ff84-4ddf-a615-677d58b9deec": 2,
    "7af60525-c63f-4c1d-b83c-5d5382cd0d7a": 1,
    "a3580100-98e2-437a-ad82-67add3fd0202": 1,
    "6741b704-30b8-4860-bd08-a9976f7f1561": 1,
    "bf150ebb-dec2-4a21-98f2-a572424bc9a9": 1,
    "e64bd011-3ab7-43dc-adab-8df1771c95e6": 1,
  },
  "b8f696b6-10c6-46c8-a2b7-82594b8fdd49": {
    "78cbcc4e-6d19-4324-a385-59b292827799": 5,
    "fd067875-8826-47ba-b7ab-07b955ee6e31": 5,
    "071a637a-8687-4f8d-a4cb-109d6bc8c547": 5,
    "7451241c-3af2-4f03-9a75-9c9de3bd4c1c": 5,
    "e972483f-8da7-4caf-8d2b-942de4527ba1": 5,
    "f75942b3-2837-4e6d-8ebc-49828cb1fdef": 5,
    "d839f764-eeed-429b-bf26-3e2d5a61c817": 5,
    "5949ee6e-b45b-4870-8851-9729d32f63d1": 5,
    "2f3cb375-bfd5-45e6-a0da-bdc9d53f98af": 5,
    "a7202d16-9719-4980-ac0e-e1d822cbb404": 5,
    "b5c706fa-082b-40a7-9f91-7656bf8717f3": 5,
    "750f7fed-538e-45cf-be1d-859ed3f5fc69": 5,
    "008a5bda-020e-4463-9420-1230176dc661": 5,
    "690b4cc3-9bf1-40c8-9be2-f31d31dff65d": 5,
    "7614ba4f-38d3-4b95-abb4-99fe359d7613": 5,
    "33f80c9f-d6ef-4d03-b4f7-a8e5d6b6ad8e": 5,
    "439dbd57-97c7-406f-9f58-c4bce80592e9": 5,
    "6b9c0957-0fe4-4eb8-9e97-12b482243202": 5,
    "a5afbd7e-f57b-4d4b-9a4b-83235491bab8": 5,
    "20f9b677-0a31-4f77-bf5f-db2bc83b51e2": 5,
    "1ac615bc-7f3e-48b9-8b16-9b7d470ba604": 5,
    "619f6005-4788-44c6-842b-403c5d530b68": 4,
    "8d16700d-fafb-4932-be7d-1810cedb89d0": 4,
    "f280a9d9-1df8-4591-b1b8-dd23083dd98c": 4,
    "c9bf3099-bab0-47a1-b002-d818bef2a461": 4,
    "777278c9-f383-4673-b6f1-62d07a44b928": 4,
    "cb8f02dc-c0e3-4ff7-b7d6-5d9e1396190a": 4,
    "926cceaa-1ed9-4872-8939-6f0dfd6caa0e": 4,
    "6b6546cb-a506-4e4e-9d8f-b528c3b4d25c": 4,
    "c1e76a0b-0fb1-4524-a143-9953b4ade992": 4,
    "4b4f2646-1514-42be-8fd3-4053a4fa812f": 4,
    "ad97b8b4-8eef-40eb-ac57-8afc33e4df13": 4,
    "0931977c-fdbc-48fa-b8a0-a20e8a32c1a1": 4,
    "549736af-99c6-41cf-9ee8-280fffc70701": 4,
    "b9bade6d-2c15-4d61-a28c-d8c74a1e9ee9": 4,
    "0bbb7ae0-ccc6-4f5f-8b81-e6de52f029ac": 4,
    "535436f9-72a0-4f88-860b-892e873afe5b": 4,
    "676ec339-b8f4-47a7-ae22-ae75a0e99c6c": 4,
    "f1542450-1d6d-4da3-ab17-e546cc7baa17": 4,
    "ef5f53bc-86fc-42db-afec-aeccf2f04c07": 4,
    "5636fb28-c9a6-4aef-88d3-83a514f03ac8": 4,
    "47ba5201-0441-403a-9bf9-9352e4056042": 4,
    "383ed1d7-b2d5-416c-b672-18e4efc4b044": 4,
    "fe204515-9a0e-4bb6-b565-206cb4bb11a5": 4,
    "dfa8e74d-4d57-4411-9873-736d2b3d625a": 4,
    "0fa85b3a-e951-487e-9ea8-324446e0b1a8": 4,
    "cfaf412b-f283-4a45-8fdf-256dc4f94117": 4,
    "03b7454a-20bf-4dc4-87b8-ca4ccefe5741": 4,
    "26d4f404-6746-406e-ae23-6184b916b0b5": 4,
    "850e7c1f-c177-4868-b245-aaba8933149c": 4,
    "3bbb5b59-fdc6-48bc-9dde-be4e22a9af99": 4,
    "5eeb4def-97c4-48fb-b529-bc95a5065dfd": 4,
    "accb358c-7b48-49d6-b7d7-15bb57f50c35": 4,
    "8d77127d-6849-446f-8aaf-366ac0a03892": 4,
    "2b92fe11-28c0-4ee7-84b5-a79f969d1ca4": 3,
    "c1e002ad-b380-4dbd-919d-8c355fa2ea75": 3,
    "244f304b-80f0-476d-99cc-0919f2b68cf8": 3,
    "1cd5593d-4bf2-4688-a451-f34c5468b6b6": 3,
    "6be14bf0-67b4-4c0e-a057-d3694fd42f6c": 3,
    "1c4258d5-d24d-4c39-81d2-157f70bdae18": 3,
    "4658fc19-c10e-4294-a7cd-06450822343a": 3,
    "aa67d673-6ee4-42e2-ba98-a4788ca83be8": 3,
    "3bbf1616-f2cf-448a-8ec7-fe172314623f": 3,
    "c0c4374a-d27d-4ea9-9fde-e0e30f10ff32": 3,
    "ef37f574-7d63-4314-9249-b75363910cca": 3,
    "1e85d82e-8bbc-4da0-b5e0-f453c7cb7f6f": 3,
    "47bbafe5-818b-42ec-9007-8b64447dddc2": 3,
    "0fe09434-d0a0-4004-8f60-90872aa25a68": 3,
    "8b787b32-fe33-4255-a0d5-0f04e06bc0ec": 3,
    "96eae914-4662-497e-8d6d-bfa7ed6d4f1b": 3,
    "823f2f85-d16c-4ad1-a966-7d9e6d731ae5": 3,
    "8a1f8768-5d2a-40df-a9b2-52f4b7b01456": 3,
    "51d57a39-c742-408d-afb7-3128b22702e9": 3,
    "a3897639-f0f5-4fb6-a4f6-cceb8882a5b5": 3,
    "2079088b-e54f-40f8-ab6a-ec3f87234952": 3,
    "f00c57cc-1295-477a-8425-b94192f82d34": 3,
    "4d2dcf67-7085-4b9d-92f4-83fcfef2edc9": 3,
    "b299b59e-a846-4144-b516-507882d2b485": 3,
    "2473493d-5f35-4a85-8dcb-3914ef3f3374": 2,
    "fed80033-b46d-471b-899f-80bcbe67261e": 2,
    "1b96c41b-e398-4adf-ba1a-c6f21a52c28f": 2,
  },
  "d12245a4-e138-538a-a8e4-47b45eabdb71": {
    "08e08887-025f-4edd-9c1b-e18955fa4ec9": 5,
    "3c72d33b-0928-4427-a52f-841fb9619546": 5,
    "4b693304-d97b-4bfe-ab34-c1cd6979a6e1": 5,
    "5bfb315c-852e-479d-9573-26019c1952d2": 5,
    "7b8e930b-902f-4869-ae7f-b84321dadeb4": 5,
    "920372cd-92bf-4bf9-a45f-4ea308d2956e": 5,
    "cfa39f36-ce45-4e85-852f-68992f4f6f10": 5,
    "102b7628-c6ad-471f-9c5a-3502b41965cc": 4,
    "10480692-5091-4881-a8d8-d108d5c4f881": 4,
    "27b9c0c8-be7b-4e79-aac0-cc2142276277": 4,
    "2ca79186-0d38-4f3a-8256-c11f1130af9e": 4,
    "4036d7d9-bd7b-49aa-8b8a-6a4cc8be3d2c": 4,
    "5341e602-05a9-4b7a-90f3-3fb58258802b": 4,
    "8056908a-ca73-4fc1-bf66-4a1592148c15": 4,
    "947cd7f9-f6bc-4e2d-bef1-5b446df34d93": 4,
    "9fe7076c-802d-437b-9cc5-44fcfd7304b0": 4,
    "ac5707cd-91bb-4ae1-b9d5-0ad4f472a5f3": 4,
    "af5f75d2-bece-450f-ba9d-1d98e80e204e": 4,
    "bd829491-efd9-4b41-beef-d771f1e21ce5": 4,
    "c6ff3e05-b285-4bce-a143-aafd47f6ade1": 4,
    "d9c5674f-1eb6-4bce-a58b-bf06c7e01863": 4,
    "ddfaa072-a3d4-484f-875c-51436ecdc393": 4,
    "e739819c-f2b0-413f-a661-e2c0cb47b52f": 4,
    "e8a4f8fd-39af-473f-ae29-7f829a0213bc": 4,
    "166edebc-0908-4ae4-a4b3-d368bdbd71c4": 4,
    "bd764ab8-0391-46f0-86a5-355cd4072a11": 4,
    "252fe514-71d6-4565-ac46-7a986d658a24": 4,
    "7e6437af-9eb9-45b7-8011-b0c09097f2af": 4,
    "821ea7fd-52cc-4e8e-b804-1d377a81e72e": 4,
    "8ee6eb15-75ca-41bd-9031-649f9db22c1b": 4,
    "f8c57b21-8e7f-4522-9eb7-9b612522f643": 4,
    "1c4312ba-033c-4d73-ac0b-05bf4df202ce": 3,
    "133e2d4a-1921-402b-8eb5-5ef564acbe5d": 3,
    "1b420268-2960-4bd0-ae56-2afdc92309dd": 3,
    "2881c726-f45b-4048-92b3-4b4187a1521e": 3,
    "2909c1e7-600a-4106-a8c9-aea55f7f52fc": 3,
    "437a44a0-8061-42a9-a5ac-00055f35581a": 3,
    "44bb8995-8670-4f3c-a420-eef88b50d2ff": 3,
    "75b2dcfd-3afe-402d-8fed-62cb7fc89f9b": 3,
    "76d75923-1b09-45c9-80e3-905da3a5457c": 3,
    "9509728e-4809-407f-b74b-b5c97e836ae6": 3,
    "9ab56ef0-eeb4-4ded-84fd-96e74c41d9fa": 3,
    "9e07120a-48f3-40f3-8a69-7222f1418704": 3,
    "c4c34fc3-e976-4084-af47-dd700982ea8c": 3,
    "cce71157-e647-4833-89a8-25529fcda574": 3,
    "d16bcf27-5601-4a87-b3f3-9bfb30ba4c8b": 3,
    "d1c75cae-28ae-44cb-a1ad-5598775b6244": 3,
    "d9458ec6-9588-411a-8059-52a127eb009a": 3,
    "de0f5483-f8f0-40ac-8a53-1278f4088fe0": 3,
    "f196268e-3f8b-407f-b7ce-fec31e4e7ae0": 3,
    "f1eb9b49-5bbb-4ac9-b56b-f0cfa5461fe6": 3,
    "f65f081a-e0b7-452d-8f53-7beec068cd23": 3,
    "fac95da4-e628-4189-a06f-6bbd547ea719": 3,
    "fd191c5e-bf30-4b92-b876-205ae670a404": 3,
    "1bacd5a7-6354-46c0-8cec-3cab25f23099": 3,
    "1da74b52-3a02-4d7b-bfd1-3fbfa05a182b": 3,
    "28a0e7ea-09d5-40da-9416-926dee590908": 3,
    "31c2464e-08ee-4ec3-8201-6d1761754382": 3,
    "3a2b8af8-96a0-49e7-bdea-23f81d00f0ba": 3,
    "41f9d825-cd1f-43a1-81ff-e8d0c1274e1b": 3,
    "5383c487-b392-4a66-baec-59a8bfc2a578": 3,
    "86ffa75b-9895-4998-9095-4235527bf2fc": 3,
    "c6da4f9c-5018-448a-ba5e-ba88c6533724": 3,
    "ebe2efe3-9274-4136-975b-eb41837aa097": 3,
    "f10d90db-39e7-4254-8c6b-27b75523c22c": 3,
    "9cc0705c-8f33-4799-8f3d-00cb938527da": 3,
    "f166f38f-27b8-4e9a-a989-524a1f6f71ff": 3,
    "0981e3eb-8ba4-4f7f-87d6-72e18b52ea2c": 2,
    "0bbcd84e-f23f-4996-af74-bd8bfdeed2f4": 2,
    "20cc239e-423e-41cb-9a50-1b35fa0d6e71": 2,
    "46061807-f6ec-478f-ad43-7a6ae787ac9d": 2,
    "63988001-158d-422b-8d0e-030ac1e4b6c4": 2,
    "9eebefbe-a905-4190-9db6-12b466a93b72": 2,
    "a3b7bfea-f4ad-4f01-a3d4-5eee6faa2e5b": 2,
    "b523df3d-7c02-49f6-a50d-744746329985": 2,
    "b97b8a1c-415e-4f5a-a9d7-1007ec12ebfc": 2,
    "d1c2dc2c-eb53-472d-81df-dd756aa2515b": 2,
    "f16c6401-6a0e-4916-a56e-a163ce8bf666": 2,
    "fc6e0f28-e18d-4552-b05f-699159ad9ed4": 2,
    "fffb546e-ec3f-44bd-8800-bcbe10a82a57": 2,
    "104d0a4e-1298-4cf4-aa7e-f8c2f1392302": 2,
    "56ed723a-7706-4c26-9eda-d6e5eecc9146": 2,
    "750550c4-dfd0-4036-a8f7-e4e1995f196b": 2,
    "98cc38d6-018a-4f35-954e-8551471e66a5": 2,
    "c57ab90d-de37-405c-9a32-d1ec37fe9a38": 2,
    "d23f3bf5-4740-4f29-9ff2-870f8509ebf1": 2,
    "c714de85-2b4a-49c8-ad27-69b53af55383": 2,
    "ed46edf4-e5f5-4d5a-9cb4-29e3c528c2e8": 2,
    "34531622-2edd-4da2-8d53-ed44d0cf0cf4": 1,
    "79f6d8f7-2217-4c3e-9906-7bae678d6240": 1,
    "86d27c7c-3ae7-49a0-b5a1-7f110666dbd1": 1,
    "c4e6118e-f6f9-4c5f-938c-f08da23848d6": 1,
  },
  "68160117-8b31-4ce5-95d2-a4d4b2d49e7a": {
    "2304c661-fa3e-4130-a375-9211ca426286": 5,
    "53c30139-6c03-4beb-bd77-3b11970f9e38": 5,
    "7bceb696-f6f0-45f9-9112-43de58acd47c": 5,
    "3dd3cc4b-089f-49aa-a86d-eb45ccaf00be": 5,
    "a2f8df45-82bf-4d22-bb55-871428612b7e": 5,
    "d4e939c8-af49-4860-9c2d-c8f470bd8ead": 5,
    "6c17e04e-9bd3-400b-a6e4-d80b3b4b36cb": 5,
    "2b8e40c6-00dd-4160-8fd3-58f52ae1bdd0": 5,
    "89974620-31e6-4d9f-a9d6-542c65bca4be": 5,
    "7291aeb6-5c05-4e21-81b6-14bfeb35750c": 5,
    "b0367aae-d02d-4bf5-a0dc-c5b035b32c8f": 4,
    "73892d5c-c899-489b-919f-87384fafaf9f": 4,
    "3c88b700-415d-4596-adc8-729fa400ee51": 4,
    "eaa487b3-2dec-4732-951d-f884258e8404": 4,
    "3429537b-00a9-4752-9e3b-dceaf865a303": 4,
    "cbe89c7b-d44a-4603-bc28-47de9136ba95": 4,
    "7410b104-ff3a-440f-a14b-34c5d3853025": 4,
    "ccc5fcb7-72d1-4c81-a8dd-6df24a05a266": 4,
    "784d3199-244c-4c8e-9734-75c115fe18ce": 4,
    "75252743-95e0-4a17-a6fe-2ba2d330d5e7": 3,
    "d862c467-d64f-455c-a6af-22e05c1a1b93": 3,
    "c7a8a2b8-6375-4273-9cef-93c45cdcc11b": 3,
    "ba9664cb-7ab0-40cd-b38a-fb73a357ffdb": 3,
    "64a84d2f-9a02-4255-9753-2c98c331f0c9": 3,
    "0d5968d9-bef8-4629-9ec3-5c872f21041a": 3,
    "b879a7fd-106f-436f-b42f-7e246a68f3bd": 3,
    "a6821ada-0b92-4703-a049-f6c97c290e05": 3,
    "6608b430-6f13-44fe-9d82-5680011c5974": 3,
    "6401a396-4cc0-4270-a70a-5b7880c4ef3d": 3,
    "cada87ab-b92f-4acb-b5a2-025f3633cfe7": 3,
    "d426275a-1216-4467-a723-762144ea9658": 3,
    "05ae4fad-363a-4bfb-84eb-b8b1a0142a22": 3,
    "e0dcde3c-5875-459b-8345-f1f0b7010771": 3,
    "0bd8035a-6fbd-4416-bd58-2ed778b31c49": 2,
  },
  "d258a9da-add0-5e1d-937f-d9a77cc5dac4": {
    "252fe514-71d6-4565-ac46-7a986d658a24": 4,
    "8ee6eb15-75ca-41bd-9031-649f9db22c1b": 4,
    "f8c57b21-8e7f-4522-9eb7-9b612522f643": 4,
    "166edebc-0908-4ae4-a4b3-d368bdbd71c4": 4,
    "821ea7fd-52cc-4e8e-b804-1d377a81e72e": 4,
    "7e6437af-9eb9-45b7-8011-b0c09097f2af": 4,
    "bd764ab8-0391-46f0-86a5-355cd4072a11": 4,
    "86ffa75b-9895-4998-9095-4235527bf2fc": 3,
    "9cc0705c-8f33-4799-8f3d-00cb938527da": 3,
    "28a0e7ea-09d5-40da-9416-926dee590908": 3,
    "f10d90db-39e7-4254-8c6b-27b75523c22c": 3,
    "5383c487-b392-4a66-baec-59a8bfc2a578": 3,
    "41f9d825-cd1f-43a1-81ff-e8d0c1274e1b": 3,
    "c6da4f9c-5018-448a-ba5e-ba88c6533724": 3,
    "1bacd5a7-6354-46c0-8cec-3cab25f23099": 3,
    "1da74b52-3a02-4d7b-bfd1-3fbfa05a182b": 3,
    "ebe2efe3-9274-4136-975b-eb41837aa097": 3,
    "3a2b8af8-96a0-49e7-bdea-23f81d00f0ba": 3,
    "31c2464e-08ee-4ec3-8201-6d1761754382": 3,
    "1c4312ba-033c-4d73-ac0b-05bf4df202ce": 3,
    "f166f38f-27b8-4e9a-a989-524a1f6f71ff": 3,
    "d1c2dc2c-eb53-472d-81df-dd756aa2515b": 2,
    "63988001-158d-422b-8d0e-030ac1e4b6c4": 2,
    "fc6e0f28-e18d-4552-b05f-699159ad9ed4": 2,
    "c714de85-2b4a-49c8-ad27-69b53af55383": 2,
    "20cc239e-423e-41cb-9a50-1b35fa0d6e71": 2,
    "b97b8a1c-415e-4f5a-a9d7-1007ec12ebfc": 2,
    "0981e3eb-8ba4-4f7f-87d6-72e18b52ea2c": 2,
    "0bbcd84e-f23f-4996-af74-bd8bfdeed2f4": 2,
    "b523df3d-7c02-49f6-a50d-744746329985": 2,
    "46061807-f6ec-478f-ad43-7a6ae787ac9d": 2,
    "fffb546e-ec3f-44bd-8800-bcbe10a82a57": 2,
    "a3b7bfea-f4ad-4f01-a3d4-5eee6faa2e5b": 2,
    "f16c6401-6a0e-4916-a56e-a163ce8bf666": 2,
    "ed46edf4-e5f5-4d5a-9cb4-29e3c528c2e8": 2,
    "9eebefbe-a905-4190-9db6-12b466a93b72": 2,
  },
};

export const inrangeScoreStatusColor = {
  5: "#01B28A",
  4: "#3E6D4F",
  3: "#A7923F",
  2: "#AD7A2D",
  1: "#4077A3",
};

export function getScoreBySiteID(orgID, siteID) {
  if (siteScoresData[orgID]) {
    if (siteScoresData[orgID][siteID] !== undefined) {
      return siteScoresData[orgID][siteID];
    }
  }
  if (siteID.startsWith("e2e-score")) {
    const score = parseInt(siteID.split("-")[2]); // In our e2e tests, when testing InRange score, we prefix site ids with "e2e-score-<score>"
    return score;
  }
  return null;
}

export function isOrganizationInSiteScores(orgID) {
  // In our e2e tests, when testing InRange score, we prefix org ids with "e2e-score"
  if (orgID.startsWith("e2e-score")) {
    return true;
  }
  return orgID in siteScoresData;
}

//TODO: reduce util files move all functions to one file.
//TODO: refacor tooltip long text in app
