import {
  useNetworkSiteList,
  useSite,
  useSiteSdm,
} from "@inrange/building-manager-api-client";
import { trackUserInteraction } from "@inrange/building-manager-api-client/events";
import { OrgSiteListEntry } from "@inrange/building-manager-api-client/models-organisation";
import {
  NetworkSite,
  SdmOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { useContextTS } from "@inrange/shared-components";
import {
  calcSiteOwnershipForOrg,
  Grid,
  Loading,
} from "@inrange/theme-components";
import {
  formatUnitsToNearestTen,
  fractionalCurrencySymbol,
  roundCurrency,
} from "@inrange/theme-components/formatting";
import { MarketplaceMap } from "@inrange/theme-components/mapping";
import {
  ExistingMatchOnMap,
  getIRR,
  getRevenue,
  getSavings,
  MarketplaceOffer,
  siteCanBuy,
  siteCanSell,
  SPILL_BY_COUNTRY,
} from "@inrange/theme-components/marketplace";
import React, { useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDataContext } from "../../AppDataContext";
import { UserContext } from "../../auth/UserContext";

interface MarketplaceProps {
  sites: OrgSiteListEntry[];
  networkSites: NetworkSite[];
  rootPath: string;
}

const formatAvalability = (availabilityMonths: number): string => {
  return availabilityMonths > 0
    ? `${availabilityMonths} ${availabilityMonths > 1 ? "months" : "month"}`
    : "Available";
};

const offerToLogEventDetails = (
  offerType: string,
  site: Site,
  offer: SdmOffer
) => {
  if (!offer) {
    return undefined;
  }
  if (offerType === "buy") {
    return {
      volume: formatUnitsToNearestTen(offer.config.volume, "kWh"),
      tariff: `${(offer.config.tariff * 100).toFixed(2)} ${fractionalCurrencySymbol(site.currencyCode)}/kWh`,
      availability: formatAvalability(offer.dest.availabilityMonths),
      sites: "From 1 site",
      savingsy1_new: roundCurrency(getSavings(site, offer.financialModels)),
      savingsy1_old: roundCurrency(getSavings(site, site.financialModels)),
      demandpct_new:
        (offer.config.volume +
          site.energyFlowAnnual.behindMeter +
          site.energyFlowAnnual.networkImport) /
        site.energyFlowAnnual.demand,
      demandpct_old:
        (site.energyFlowAnnual.behindMeter +
          site.energyFlowAnnual.networkImport) /
        site.energyFlowAnnual.demand,
      co2_new: offer.emissionsAvoidedYearOne,
      co2_old: site.calculations.emissionsAvoided.totalAvoidance,
    };
  }
  return {
    volume: formatUnitsToNearestTen(offer.config.volume, "kWh"),
    tariff: `${(offer.config.tariff * 100).toFixed(2)} ${fractionalCurrencySymbol(site.currencyCode)}/kWh`,
    availability: formatAvalability(offer.dest.availabilityMonths),
    sites: "From 1 site",
    revenuey1_new: roundCurrency(getRevenue(site, offer.financialModels)),
    revenuey1_old: roundCurrency(getRevenue(site, site.financialModels)),
    irr_new: getIRR(site, offer.financialModels),
    irr_old: getIRR(site, site.financialModels),
  };
};

// eslint-disable-next-line no-unused-vars
const Marketplace: React.FC<MarketplaceProps> = ({
  sites,
  networkSites,
  rootPath,
}) => {
  const { user } = useContext(UserContext);
  const { appData } = useContextTS(AppDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const offerType =
    params.get("offerType") ||
    (appData?.organisation?.orgType === "Landlord" ? "sell" : "buy");
  const siteId = params.get("siteId") || undefined;
  const { fetchSite } = useSite({
    siteId,
    userOrgId: appData!.organisation!.id,
    app: "customer",
  });
  const site = fetchSite.data?.site;
  const urlSpillSiteId = params.get("spillSiteId") || undefined;
  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
  const spillSiteId: string | undefined =
    urlSpillSiteId ||
    (site?.countryCode && !testMode
      ? SPILL_BY_COUNTRY[site?.countryCode]
      : undefined);

  const ownership =
    site && appData!.organisation
      ? calcSiteOwnershipForOrg(site, appData!.organisation)
      : undefined;
  // Treat "none" as "license"
  const canShowBuyOffer =
    offerType === "buy" && siteCanBuy(appData!.organisation!, site);
  const canShowSellOffer =
    offerType === "sell" && siteCanSell(appData!.organisation!, site);
  const siteSelected = !!siteId;
  const { fetchSiteSdmList, bestBuyOffer, bestSellOffer } = useSiteSdm({
    siteId,
    getAllResults: false,
    onlyLinkedOrgs: !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE,
    userOrgId: appData!.organisation!.id,
  });

  const sitesById = useMemo(() => {
    return sites.reduce(
      (acc, site) => {
        acc[site.id] = site;
        return acc;
      },
      {} as Record<string, OrgSiteListEntry>
    );
  }, [sites]);

  const networkSitesById = useMemo(() => {
    return networkSites.reduce(
      (acc, site) => {
        acc[site.id] = site;
        return acc;
      },
      {} as Record<string, NetworkSite>
    );
  }, [networkSites]);

  /*
   * Find any sites that are involved in a match but are not in the list of sites
   * that we have already fetched. This can happen when a user has a match with a
   * site that is no longer eligible to be in their "InRange network" e.g. if the
   * site is in an org which was previously linked to this org, but is no longer.
   */
  const extraNetworkSiteIds = useMemo(() => {
    const matchSiteIds = sites.reduce((acc, site) => {
      for (const match of site.sdmMatches) {
        if (match.buyerId === site.id) {
          acc.add(match.sellerId);
        } else {
          acc.add(match.buyerId);
        }
      }
      return acc;
    }, new Set<string>());
    const result = Array.from(matchSiteIds).filter((matchSiteId) => {
      return !sitesById[matchSiteId] && !networkSitesById[matchSiteId];
    });
    if (
      spillSiteId &&
      !sitesById[spillSiteId] &&
      !networkSitesById[spillSiteId] &&
      result.indexOf(spillSiteId) === -1
    ) {
      result.push(spillSiteId);
    }
    return result;
  }, [sites, sitesById, networkSitesById, spillSiteId]);
  const { useNetworkSiteData } = useNetworkSiteList(
    appData?.organisation?.id,
    true,
    testMode,
    appData?.organisation?.id
  );
  const extraNetworkSites = useNetworkSiteData(extraNetworkSiteIds);
  const successfulExtraNetworkSites: NetworkSite[] = extraNetworkSites
    .filter((query) => query.isSuccess)
    .map((query) => query.data?.sites || [])
    .flat();
  const networkSitesWithExtras = useMemo(() => {
    return networkSites.concat(successfulExtraNetworkSites);
  }, [networkSites, successfulExtraNetworkSites]);

  const networkSitesWithExtrasById = useMemo(() => {
    return networkSitesWithExtras.reduce(
      (acc, site) => {
        acc[site.id] = site;
        return acc;
      },
      {} as Record<string, NetworkSite>
    );
  }, [networkSitesWithExtras]);

  const handleSiteIdChange = (newSiteId: string | undefined) => {
    if (newSiteId === undefined) {
      navigate(`${rootPath}marketplace`, { replace: true });
    } else {
      const newSite = sitesById[newSiteId];
      const canBuy = siteCanBuy(appData!.organisation!, newSite);
      const canSell = siteCanSell(appData!.organisation!, newSite);
      // When switching between sites in the dropdown, we always prefer to show a sell offer
      // unless the site can't sell
      const newOfferType = canBuy && !canSell ? "buy" : "sell";
      navigate(
        `${rootPath}marketplace?siteId=${newSiteId}&offerType=${newOfferType}`,
        { replace: true }
      );
    }
  };

  const sdmOffer = useMemo(() => {
    if (canShowBuyOffer && bestBuyOffer) {
      return bestBuyOffer;
    } else if (canShowSellOffer && bestSellOffer) {
      return bestSellOffer;
    }
    return undefined;
  }, [canShowBuyOffer, bestBuyOffer, canShowSellOffer, bestSellOffer]);

  const [offerSite, existingMatchSites]: [
    OrgSiteListEntry | NetworkSite | undefined,
    ExistingMatchOnMap[] | undefined,
  ] = useMemo(() => {
    if (site) {
      if (offerType === "buy") {
        // Buy match
        const existingBuyMatches = site.sdmMatches
          .filter(
            (match) => !sdmOffer || match.sellerId !== sdmOffer.config.sellerId
          )
          .filter((match) => match.buyerId === site.id)
          .map((match) => {
            return {
              site:
                sitesById[match.sellerId] ||
                networkSitesWithExtrasById[match.sellerId],
              match,
              isSpillMatchUpdated: false,
            } as ExistingMatchOnMap;
          });
        return [
          sdmOffer
            ? sitesById[sdmOffer.dest.id] ||
              networkSitesWithExtrasById[sdmOffer.dest.id]
            : undefined,
          existingBuyMatches,
        ];
      } else {
        // Sell match
        const existingSellMatches = site.sdmMatches
          .filter(
            (match) => !sdmOffer || match.buyerId !== sdmOffer.config.buyerId
          )
          .filter((match) => match.sellerId === site.id)
          .map((match) => {
            return {
              site:
                sitesById[match.buyerId] ||
                networkSitesWithExtrasById[match.buyerId],
              match,
              isSpillMatchUpdated: false,
            } as ExistingMatchOnMap;
          });
        // Add details of the existing spill match, as long as it's not the same as the current sell match
        const spillSite = spillSiteId
          ? sitesById[spillSiteId] || networkSitesWithExtrasById[spillSiteId]
          : undefined;
        const spillVolume =
          site.energyFlowAnnual.exported -
          (sdmOffer ? sdmOffer.config.volume : 0);
        if (
          spillSite &&
          spillVolume > 0 &&
          (!sdmOffer || sdmOffer.dest.id !== spillSiteId) &&
          existingSellMatches.find(
            (match) => match.site && match.site.id === spillSiteId
          ) === undefined
        ) {
          const spillMatch = {
            site: spillSite,
            match: {
              buyerId: spillSiteId,
              sellerId: site.id,
              tariff: site.inrangeExportTariff,
              volume:
                site.energyFlowAnnual.exported -
                (sdmOffer ? sdmOffer.config.volume : 0),
              ppaContractType: "sleeved",
              ppaLength: site.exportPPALength,
              ppaIndex: site.exportPPAIndex,
            },
            isSpillMatchUpdated: !!sdmOffer,
          } as ExistingMatchOnMap;
          existingSellMatches.push(spillMatch);
        }
        // Return the computed data
        return [
          sdmOffer
            ? sitesById[sdmOffer.dest.id] ||
              networkSitesWithExtrasById[sdmOffer.dest.id]
            : undefined,
          existingSellMatches,
        ];
      }
    }
    return [undefined, undefined];
  }, [
    site,
    sdmOffer,
    offerType,
    sitesById,
    networkSitesWithExtrasById,
    spillSiteId,
  ]);

  useEffect(() => {
    if (sdmOffer && site && appData!.organisation) {
      // User saw an offer
      trackUserInteraction(
        {
          organisation_name: appData!.organisation.name,
          site_id: site.id,
          site_name: site.name,
          site_operational_status: site.operationalStatus,
          ownership: ownership,
          investment_model: site.activeInvestmentModel,
          offer_type: offerType,
          sdm_match: sdmOffer,
          offer_log_values: offerToLogEventDetails(offerType, site, sdmOffer),
        },
        "ENERGY_OFFER",
        "ENERGY_OFFER_SEEN",
        user!.email.toLowerCase(),
        "customer-app"
      );
    }
    if (
      !fetchSiteSdmList.isLoading &&
      !sdmOffer &&
      site &&
      appData!.organisation
    ) {
      // User saw no offer available
      trackUserInteraction(
        {
          organisation_name: appData!.organisation.name,
          site_id: site.id,
          site_name: site.name,
          site_operational_status: site.operationalStatus,
          offer_type: offerType,
        },
        "ENERGY_OFFER",
        "ENERGY_OFFER_SEEN",
        user!.email.toLowerCase(),
        "customer-app"
      );
    }
  }, [
    user,
    appData,
    site,
    ownership,
    offerType,
    sdmOffer,
    fetchSiteSdmList.isLoading,
  ]);

  if (
    fetchSite.isLoading ||
    extraNetworkSites.some((query) => query.isLoading)
  ) {
    return <Loading label="Loading your site data..." />;
  }

  return (
    <Grid $cols={2} $colwidth={"1fr"} $gap={"5px"} $margin={"5px 0px 0px 0px"}>
      <MarketplaceMap
        width="100%"
        height="750px"
        org={appData!.organisation!}
        mySites={sites}
        sitesById={sitesById}
        networkSites={networkSitesWithExtras}
        networkSitesById={networkSitesWithExtrasById}
        onSiteClick={(clickedSite) => {
          trackUserInteraction(
            {
              organisation_name: appData!.organisation!.name,
              site_id: clickedSite.id,
              site_name: "name" in clickedSite ? clickedSite.name : undefined,
              site_operational_status:
                "operationalStatus" in clickedSite
                  ? clickedSite.operationalStatus
                  : undefined,
              offer_type: siteSelected
                ? params.get("offerType") || undefined
                : undefined,
            },
            "ENERGY_OFFER",
            "SITE_CLICKED",
            user!.email.toLowerCase(),
            "customer-app"
          );
        }}
        offerType={offerType}
        sdmOffer={sdmOffer}
        selectedSite={site}
        offerSite={offerSite}
        existingMatchSites={existingMatchSites}
      />
      <MarketplaceOffer
        org={appData!.organisation!}
        siteId={siteId}
        userEmail={user!.email}
        sites={sites}
        site={site}
        offerSite={offerSite}
        setSelectedSiteIdFn={handleSiteIdChange}
        addSiteUrl={`${rootPath}add-site`}
        notifyAddSiteFn={() => {
          trackUserInteraction(
            {
              from_page: "marketplace",
              site_name: site?.name,
            },
            "ADD_SITE",
            "ADD_SITE_ENTERED",
            user!.email.toLowerCase(),
            "customer-app"
          );
        }}
        type={offerType}
        isLoading={siteSelected && (fetchSiteSdmList.isLoading || !site)}
        sdmOffer={sdmOffer}
        acceptOfferFn={() => {
          if (sdmOffer) {
            trackUserInteraction(
              {
                organisation_name: appData!.organisation!.name,
                site_id: site!.id,
                site_name: site!.name,
                site_operational_status: site!.operationalStatus,
                ownership: ownership,
                investment_model: site!.activeInvestmentModel,
                offer_type: offerType,
                sdm_match: sdmOffer,
                offer_log_values: offerToLogEventDetails(
                  offerType,
                  site!,
                  sdmOffer
                ),
              },
              "ENERGY_OFFER",
              "ENERGY_OFFER_REQUESTED",
              user!.email.toLowerCase(),
              "customer-app"
            );
          } else {
            trackUserInteraction(
              {
                organisation_name: appData!.organisation!.name,
                site_id: site!.id,
                site_name: site!.name,
                site_operational_status: site!.operationalStatus,
                ownership: ownership,
                investment_model: site!.activeInvestmentModel,
                offer_type: offerType,
              },
              "ENERGY_OFFER",
              "ENERGY_OFFER_FIND_MATCH_REQUESTED",
              user!.email.toLowerCase(),
              "customer-app"
            );
          }
        }}
      />
    </Grid>
  );
};

export default Marketplace;
