import { useSiteFileDownload } from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import {
  Building,
  getBuildingCoordinates,
  Panel,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { usePvGeoJson } from "@inrange/inrange-data-api-client";
import {
  Column,
  Loading,
  NoPaddedRow,
  PageCardHeader,
} from "@inrange/theme-components";
import {
  formatUnits,
  formatUnitsToNearestTen,
} from "@inrange/theme-components/formatting";
import { Map, StyledGeoJsonGroup } from "@inrange/theme-components/mapping";
import { SiteMetricCard } from "@inrange/theme-components/site";
import * as Sentry from "@sentry/react";
import L, { latLngBounds, LatLngBounds } from "leaflet";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

interface PvSystemViewProps {
  organisation: Organisation;
  site: Site;
}

const PvSystemView: React.FC<PvSystemViewProps> = ({ organisation, site }) => {
  const { generationHeadroomKva } = site.substationData;
  const panelCount = site.pvPanelCount;
  // eslint-disable-next-line react/jsx-key
  const panelName = [
    site.pvPanelName.split(" ")[0],
    site.pvPanelName.split(" ")[1],
  ];

  const currentPvDesignImage = site.siteFiles?.find(
    (file) => file.fileType === "pvDesignImage"
  );

  const batteryDetails =
    site.batteryPower && site.batteryCapacity
      ? [`${site.batteryPower} MW / ${site.batteryCapacity} MWh`]
      : undefined;

  if (batteryDetails && site.batteryModel) {
    batteryDetails.push(site.batteryModel);
  }

  const tooltipText =
    "Panel count and placement is defined based on satellite imagery of the site roof by detecting obstructions such as windows, satellites, plant equipment, or stairwells.\n\nAccuracy is impacted by image age, markings or artifacts on the roof interpreted as obstructions to panels, roof slope, and shadows.\n\nFull PV design is subject to further analysis.";
  return (
    <>
      <Column>
        <PageCardHeader
          title="PV System"
          label="Check out the potential of the rooftop PV system for this site. PV systems can be optimised for different factors, and are subject to further  analysis."
        />
      </Column>
      <PvSystemViewWrapper>
        <MetricsSidebar $isBesideMap={!currentPvDesignImage}>
          <SiteMetricCard
            title="Panel details"
            value={panelName}
            color="blue"
            tooltipText={
              "The manufacturer and make of the panels we found to be most cost efficient for this site. Subject to change based on inventory and other factors."
            }
            dataTestId="panelDetails"
          />
          <SiteMetricCard
            title="Panel count"
            value={formatUnits(panelCount, "", 0)}
            color="blue"
            tooltipText={
              "The number of panels we established could reasonably fit on the roof given obstructions we detected such as skylights or edges. Subject to optimisations and on-site evaluation."
            }
            dataTestId="panelCount"
          />
          <SiteMetricCard
            title="Inverter details"
            value={site.pvInverterBrand}
            color="blue"
            tooltipText={
              "The manufacturer of the inverters we found to be most cost efficient for this site. Subject to change based on inventory and other factors."
            }
            dataTestId="inverterDetails"
          />
          <SiteMetricCard
            title="Installed capacity"
            value={formatUnits(site.installedCapacity, "kWp", 1)}
            color="blue"
            tooltipText={
              "The estimated kilowatt peak of this site’s system given factors such as irradiation, positioning, number of panels, and so on. Subject to optimisations and other factors."
            }
            dataTestId="installedCapacity"
          />
          {batteryDetails && (
            <SiteMetricCard
              title="Battery details"
              value={batteryDetails}
              color="grey"
              tooltipText={
                "The selected battery which maximises usage of the energy generated by the PV system to the on-site demand."
              }
              dataTestId="batteryDetails"
            />
          )}
          <SiteMetricCard
            title="Available grid capacity"
            value={formatUnitsToNearestTen(
              site.exportLimit !== null && site.exportLimit !== undefined
                ? site.exportLimit
                : generationHeadroomKva,
              "kVA"
            )}
            note="To install PV system capacity size"
            tooltipText={
              "The projected network availability for excess generation at the substation for this site, using a measure of apparent power. Subject to change based on DNO capacity changes."
            }
            dataTestId="availableGridCapacity"
          />
        </MetricsSidebar>
        {currentPvDesignImage ? (
          <UploadedPvDesignImageWrapper>
            <UploadedPvDesignImage
              orgId={organisation.id}
              siteId={site.id}
              currentPvDesignImage={currentPvDesignImage}
            />
          </UploadedPvDesignImageWrapper>
        ) : (
          <PvSystemMap
            siteId={site.id}
            buildings={site.buildings}
            pvPanel={site.pvPanel}
            lat={site.latitude}
            lon={site.longitude}
            tooltipText={tooltipText}
            installedCapacity={site.installedCapacity}
          />
        )}
      </PvSystemViewWrapper>
    </>
  );
};

export default PvSystemView;

interface PvSystemMapProps {
  siteId: string;
  buildings: Building[];
  pvPanel: Panel;
  installedCapacity: number;
  lat: number;
  lon: number;
  tooltipText: string;
}

const PvSystemMap: React.FC<PvSystemMapProps> = ({
  siteId,
  buildings,
  pvPanel,
  installedCapacity,
  lat,
  lon,
  tooltipText,
}) => {
  const [map, setMap] = useState<L.Map | undefined>();

  const isCustomSiteBoundaries = buildings.some(
    (b) => b.polygonType === "custom"
  );

  useEffect(() => {
    const bounds: LatLngBounds = latLngBounds([L.latLng(lat, lon)]);
    for (const building of buildings) {
      const buildingCoordinates = getBuildingCoordinates(building);
      for (const buildingCoordinate of buildingCoordinates[0]) {
        bounds.extend(L.latLng(buildingCoordinate[1], buildingCoordinate[0]));
      }
    }
    if (map) {
      try {
        map.flyToBounds(bounds);
      } catch (e) {
        // We know this can happen, possibly related to https://github.com/Leaflet/Leaflet/issues/9527
        Sentry.captureException(e);
      }
    }
  }, [map, lat, lon, buildings]);

  const { data: pvGeoJsonResponse } = usePvGeoJson(siteId, pvPanel);

  const styledGeoJsonGroups: StyledGeoJsonGroup[] = useMemo(() => {
    const buildingsGeoJson = buildings.map((building) => {
      const geoJsonBuilding = {
        ...building.geometry,
        coordinates: getBuildingCoordinates(building),
      };
      return {
        key: building.id,
        geoJson: geoJsonBuilding,
        color: "#000239",
      };
    });

    if (!pvPanel) {
      return [{ geoJsons: buildingsGeoJson, key: "objects" }];
    }

    const panelsGeoJson = (pvGeoJsonResponse?.data || [])
      .map((buildingPanels) => {
        return buildingPanels.map((panel: string, index: number) => {
          return {
            key: `panel-${index}-${panel}`,
            geoJson: JSON.parse(panel),
            color: "#00022F",
            fillColor: "#00022F",
            fillOpacity: isCustomSiteBoundaries ? 0.4 : 0.2,
            weight: 1,
          };
        });
      })
      .flat();
    const panelsCount = panelsGeoJson.length;
    const pvSystemInstalledCapacity = (panelsCount * pvPanel.pnom) / 1000;
    const siteInstalledCapacity = installedCapacity;
    const siteVsPvSystemInstalledCapacityRatio =
      siteInstalledCapacity / pvSystemInstalledCapacity;
    const panelsGeoJsonForInstalledCapacity = panelsGeoJson.slice(
      0,
      panelsCount * siteVsPvSystemInstalledCapacityRatio
    );

    return [
      {
        geoJsons: [...buildingsGeoJson, ...panelsGeoJsonForInstalledCapacity],
        key: "objects",
      },
    ];
  }, [
    buildings,
    pvGeoJsonResponse,
    pvPanel,
    installedCapacity,
    isCustomSiteBoundaries,
  ]);

  return (
    <div
      data-testid={`pvpanels-loaded-${!!pvGeoJsonResponse?.data}`}
      style={{ width: "100%" }}
    >
      <Map
        width={"100%"}
        height={"750px"}
        lat={lat}
        lon={lon}
        zoom={20}
        styledGeoJsonGroups={styledGeoJsonGroups}
        setMap={setMap}
        useGoogleMapsBaseLayer={isCustomSiteBoundaries}
        tooltipText={tooltipText}
      />
    </div>
  );
};

interface UploadedPvDesignImageProps {
  orgId: string;
  siteId: string;
  currentPvDesignImage: {
    fileName: string;
  };
}

const UploadedPvDesignImage: React.FC<UploadedPvDesignImageProps> = ({
  orgId,
  siteId,
  currentPvDesignImage,
}) => {
  const { fetchSiteFileURL } = useSiteFileDownload({
    siteId: siteId,
    file: currentPvDesignImage,
    isAttachment: false,
    enabled: true,
    userOrgId: orgId,
  });

  if (fetchSiteFileURL.isLoading) {
    return <Loading label="Loading PV Design image..." />;
  }

  const siteFileURL = fetchSiteFileURL.data.url;
  const isPdf = currentPvDesignImage.fileName.endsWith(".pdf");

  if (isPdf) {
    return (
      <embed
        src={siteFileURL}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    );
  }

  // centered image
  return (
    <img
      src={siteFileURL}
      alt="PV Design"
      width={"100%"}
      style={{ maxWidth: "100vh" }}
    />
  );
};

const UploadedPvDesignImageWrapper = styled.div`
  width: 100%;
  padding: 0 8px 0 0;
`;

const PvSystemViewWrapper = styled(NoPaddedRow)`
  padding: 0 8px 0 8px;
  gap: 8px;
`;

const MetricsSidebar = styled.div<{ $isBesideMap: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${({ $isBesideMap }) => ($isBesideMap ? "13px" : "0")};
`;
