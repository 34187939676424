import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import {
  FinancialModelScenarios,
  NetworkSite,
  SdmMatchConfig,
  Site,
  SiteOwnership,
} from "@inrange/building-manager-api-client/models-site";
import { calcSiteOwnershipForOrg } from "@inrange/theme-components";

const isSiteOwnerOccupied = (siteOwnerships: SiteOwnership[]) => {
  const isOwnerOccupied = siteOwnerships.some(
    (siteOwnerhip) => siteOwnerhip.ownership === "ownerOccupier"
  );
  return isOwnerOccupied;
};

export const SPILL_BY_COUNTRY = {
  GBR: "cb8a6277-07dc-4a68-91d8-cefe676f1a10", // LON1
  NLD: "9a0a3f96-96ad-456e-85d2-8a456d4e543f", // AMS1
};

export const PPA_CONTRACT_TYPE = {
  sleeved: "Sleaved",
  vppa: "VPPA",
};

export interface ExistingMatchOnMap {
  site: OrgSiteListEntry | NetworkSite;
  match: SdmMatchConfig;
  isSpillMatchUpdated: boolean;
}

export const getSavings = (
  site: Site,
  financialModels: FinancialModelScenarios
) => {
  if (isSiteOwnerOccupied(site.siteOwnerships)) {
    return financialModels.ownerOccupier[site.activeInvestmentModel].savings;
  }
  return financialModels.tenant.savings;
};

export const getRevenue = (
  site: Site,
  financialModels: FinancialModelScenarios
) => {
  if (isSiteOwnerOccupied(site.siteOwnerships)) {
    return financialModels.ownerOccupier[site.activeInvestmentModel].revenue;
  }
  return financialModels.landlord.license.revenue;
};

export const getIRR = (
  site: Site,
  financialModels: FinancialModelScenarios
) => {
  if (isSiteOwnerOccupied(site.siteOwnerships)) {
    return financialModels.ownerOccupier.license.irr!;
  }
  return financialModels.landlord.license.irr!;
};

export const siteCanBuy = (
  organisation: Organisation,
  site?: Site | OrgSiteListEntry
): boolean => {
  if (organisation && site) {
    const isNorthernIreland =
      site.countryCode === "GBR" && site.dno?.name === "Unknown";
    if (isNorthernIreland) {
      return false;
    }
    const ownership = calcSiteOwnershipForOrg(site, organisation);
    if (ownership === "tenant" || ownership === "ownerOccupier") {
      return true;
    }
  }
  return false;
};

export const siteCanSell = (
  organisation: Organisation,
  site?: Site | OrgSiteListEntry
): boolean => {
  if (organisation && site) {
    const isNorthernIreland =
      site.countryCode === "GBR" && site.dno?.name === "Unknown";
    if (isNorthernIreland) {
      return false;
    }
    if (site.installedCapacity === 0) {
      return false;
    }
    const ownership = calcSiteOwnershipForOrg(site, organisation);
    if (
      site.installedCapacity > 0 &&
      (ownership === "landlord" || ownership === "ownerOccupier") &&
      site.activeInvestmentModel === "license"
    ) {
      return true;
    }
  }
  return false;
};

export const siteCanOnlySellThroughWire = (
  site?: Site | OrgSiteListEntry
): boolean => {
  return (
    site?.energyFlowAnnual.exported === 0 &&
    site.siteOwnerships.some((so) => so.park !== undefined && so.park !== null)
  );
};

export const siteHasExistingWiredMatch = (
  site?: Site | OrgSiteListEntry
): boolean => {
  return site?.sdmMatches.some((match) => match.isWired) || false;
};
