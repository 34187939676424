import { Site } from "@inrange/building-manager-api-client/models-site";
import React, { useState } from "react";
import {
  Confirmation,
  Description,
  MarketplaceButton,
  NoOfferComparison,
} from "./marketplace-styles";
import WithReturnToExploreLink from "./WithReturnToExploreLink";
import YourSiteTodayBeforeBuyOffer from "./YourSiteTodayBeforeBuyOffer";
import YourSiteTodayBeforeSellOffer from "./YourSiteTodayBeforeSellOffer";

const MarketplaceNoOfferDetails: React.FC<{
  site: Site;
  type: string;
  acceptOfferFn: () => void;
  setSelectedSiteIdFn: (siteId?: string) => void;
}> = ({ site, type, acceptOfferFn, setSelectedSiteIdFn }) => {
  const [acceptedOffer, setAcceptedOffer] = useState(false);
  const isExistingWiredMatches = site.sdmMatches.some((match) => match.isWired);
  const isExportNotSupported = site.exportLimit === 0;
  return (
    <>
      <Description data-testid={`marketplace-offer-description-loading-false`}>
        {type === "buy"
          ? `We weren't able to find an additional Marketplace offer automatically.  Contact us and we'll work together to find the right offer for your needs!`
          : // Sell matches:
            isExistingWiredMatches
            ? "Your site is wired to another site! If you'd like to explore other selling opportunities, contact us."
            : isExportNotSupported
              ? "We weren't able to automatically find a suitable nearby site to wire to. Contact us to explore selling opportunities."
              : `You've already got a great offer! We weren't able to find an additional Marketplace offer automatically.  Contact us to explore other selling opportunities.`}
      </Description>
      <NoOfferComparison>
        {type === "buy" && <YourSiteTodayBeforeBuyOffer site={site as Site} />}
        {type === "sell" && (
          <YourSiteTodayBeforeSellOffer
            site={site as Site}
            renderBlankOfferRow={false}
          />
        )}
        {!acceptedOffer && (
          <WithReturnToExploreLink setSelectedSiteIdFn={setSelectedSiteIdFn}>
            <MarketplaceButton
              height="60px"
              $width="200px"
              fontWeight="400"
              fontSize="14px"
              label={
                type === "buy"
                  ? `Help me find an offer to buy more energy`
                  : // Sell matches:
                    isExistingWiredMatches || isExportNotSupported
                    ? "Let's explore opportunities"
                    : `Help me find a better offer to sell energy`
              }
              onClick={() => {
                setAcceptedOffer(true);
                acceptOfferFn();
              }}
              data-testid="marketplace-accept-offer-button"
            />
          </WithReturnToExploreLink>
        )}
        {acceptedOffer && (
          <>
            <Confirmation>
              {type === "buy"
                ? "A member of our team will reach out shortly to explore other procurement opportunities."
                : "A member of our team will reach out shortly to explore other selling opportunities."}
            </Confirmation>
            <WithReturnToExploreLink setSelectedSiteIdFn={setSelectedSiteIdFn}>
              <MarketplaceButton
                height="60px"
                $width="200px"
                fontWeight="400"
                fontSize="14px"
                fontColor={"#000"}
                label={"Your request was sent"}
                disabled={true}
              />
            </WithReturnToExploreLink>
          </>
        )}
      </NoOfferComparison>
    </>
  );
};

export default MarketplaceNoOfferDetails;
